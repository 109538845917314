import React, { FC } from 'react';
import { selectSession, SessionState } from '../../app/store/session/sessionSlice';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { UserPermissions } from '../../interfaces/session.types';

interface Props {
  permission?: UserPermissions
}

export const ProtectedRoute: FC<React.PropsWithChildren<Props>> = ({ children, permission }) => {
  const currentSession: SessionState = useSelector(selectSession);
  const location = useLocation()
  let redirect = location.pathname
  if (location.search !== "") {
    redirect += `?${encodeURIComponent(location.search)}`
  }
  if (currentSession.authStatus && permission && !currentSession.permissions.find(item => item === permission)) {
    return <Navigate to="/" replace />;
  }

  return currentSession.authStatus
    ? <>{children}</>
    : <Navigate to={`/login?redirect=${redirect}`} replace />;
};
