import React from 'react';
import { List, Stack } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '../icons/LogoutIcon';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarMenu, SidebarMenuItem } from './SidebarMenu';
import SidebarItem from './SidebarItem';
import { logout } from '../../app/store/';
import { officeModalOpen, OfficeState, selectOffice } from '../../app/store/office/officeSlice';
import OfficeModal from '../modals/OfficeModal';
import { SystemLocalesEnum, UserPermissions } from '../../interfaces/session.types';
import { getCompanyValueBySlug } from '../../services/attributes';
import { RootState, store } from '../../app/store';
import { AttrSlugType } from '../../interfaces/attributes.interface';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { selectSession, SessionState } from '../../app/store/session/sessionSlice';
import { CrisisAlert, Home } from '@mui/icons-material';
import { setup } from '../../config/server.config';

interface SidebarProps {
  children?: React.ReactElement;
}

export default function Sidebar(props: React.PropsWithChildren<SidebarProps>) {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const currentOffices: OfficeState = useSelector(selectOffice);
  const currentSession: SessionState = useSelector(selectSession);
  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );

  const companyMenuItems: SidebarMenuItem[] = [
    {
      text: t('x.sidebar.subnav.link_label.company_settings'),
      ref: `${setup.LOOP_NOW_FE}company/settings`,
    },
    {
      text: t('x.sidebar.subnav.link_label.user_management'),
      ref: `${setup.LOOP_NOW_FE}company/users`,
    },
    {
      text: t('x.sidebar.subnav.link_label.manage_attributes'),
      ref: `${setup.LOOP_NOW_FE}company/attributes`,
    },
  ];

  const officeMenuItems: SidebarMenuItem[] = [
    { text: t('spaceloop.sidebar.create_new_office'), onClick: () => dispatch(officeModalOpen()) },
    ..._.orderBy(
      currentOffices.offices
        .map(item => ({
          text: `${item.name[companyLanguage]}`,
          ref: `/offices/${item.id}`,
        }))
        .filter(subitem => subitem.text !== 'undefined'),
      ['text'],
      'asc',
    ),
  ];

  const bookingMenuItems: SidebarMenuItem[] = [
    {
      text: t('spaceloop.sidebar.book'),
      ref: `/booking/init`,
    },
    {
      text: t('spaceloop.sidebar.my_bookings'),
      ref: `/bookings/${currentSession.user?.uuid}`,
    },
  ];

  const handleSignOut = () => {
    dispatch(logout());
  };

  if (!currentSession.authStatus) {
    return <>{props.children}</>;
  }

  const isAdmin = !!currentSession.permissions.find(p => p === UserPermissions.ManageCompany);

  return (
    <>
      <Stack position="sticky" left={0} top={0} width={60} height="100vh" zIndex={2}>
        <Stack flex={1}>
          <Stack flex={1}>
            <List disablePadding>
              {isAdmin && currentSession.company && <SidebarItem
                icon={<HomeIcon />}
                menu={<SidebarMenu title={currentSession.company.name.toUpperCase()} menuItems={companyMenuItems} />}
              />}
              {isAdmin && <SidebarItem
                icon={<BusinessIcon />}
                menu={<SidebarMenu title={t('spaceloop.sidebar.offices')} menuItems={officeMenuItems} />}
              />}
              <SidebarItem
                icon={<EventIcon />}
                menu={<SidebarMenu title={t('spaceloop.sidebar.bookings')} menuItems={bookingMenuItems} />}
              />
              {currentSession.company?.features.okr && (
                <SidebarItem
                  icon={<CrisisAlert />}
                  onClick={() => window.location.assign(`${setup.LOOP_NOW_FE}okrs`)}
                  menu={
                    <SidebarMenu
                      titleHref={`${setup.LOOP_NOW_FE}okrs`}
                      title={t('x.sidebar.subnav.header.objectives_and_keyresults')}
                      menuItems={[]}
                    />
                  }
                />
              )}
              {currentSession.company?.features.survey && (
                <SidebarItem
                  icon={<Home />}
                  onClick={() => window.location.assign(`${setup.LOOP_NOW_FE}user/home`)}
                  menu={
                    <SidebarMenu
                      title={t('x.user.home.header.title')}
                      titleHref={`${setup.LOOP_NOW_FE}user/home`}
                      menuItems={[]}
                    />
                  }
                />
              )}
            </List>
          </Stack>
          <Stack flex={1} justifyContent="flex-end">
            <List>
              <SidebarItem icon={<LogoutIcon />} onClick={handleSignOut} />
            </List>
          </Stack>
        </Stack>
      </Stack>
      {props.children}
      <OfficeModal />
    </>
  );
}
