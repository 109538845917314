import React, { FC, useEffect, useState } from 'react';
import { Floor } from '../../../interfaces/floor.interface';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { uploadFloorPlan } from '../../store/floor/action';
import { useDispatch, useSelector } from 'react-redux';
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import {
  BookableMarker,
  BookableState,
  selectBookable,
  setCurrentBookableMarker,
  setSelectedBookable,
} from '../../store/bookable/bookableSlice';
import { getBookables } from '../../store/bookable/actions';
import { useMarkerMenu } from '../../../hooks/useMarkerMenu';
import { ContextMenu } from '../../../components/menu/ContextMenu';
import { CustomMarker } from '../../../components/marker/CustomMarker';
import { useTranslation } from 'react-i18next';

interface Props {
  floor: Floor;
}

export const Floorplan: FC<Props> = ({ floor }) => {
  const [imageName, setImageName] = useState<string>('');
  const dispatch = useDispatch<any>();
  const currentBookables: BookableState = useSelector(selectBookable);
  const { x, y, showMenu, handleClose: handleMenuClose, inputEl } = useMarkerMenu();
  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [t] = useTranslation();

  const bookableCounts = currentBookables.bookables.reduce((carry, bookable) => {
    return { ...carry, [bookable.type]: carry[bookable.type] + 1 };
  }, { desk: 0, meeting_room: 0, custom: 0 });

  const normalizeMarkers = () => {
    const bookables = currentBookables.bookables;
    const normalizedMarkers = bookables.map(({ x, y }) => {
      return { left: x, top: y };
    });
    setMarkers(normalizedMarkers);
  };

  const ensureValidCoordinate = (n: number): number => {
    n = Math.round(n);
    // To ensure that the bookable remains within the viewport
    if (n > 99) {
      return 99;
    }
    if (n < 0) {
      return 0;
    }

    return n;
  };

  const handleNewBookable = ({ top, left }: Marker) => {
    const newBookableMarker: BookableMarker = {
      itemNumber: currentBookables.bookables.length,
      x: ensureValidCoordinate(left.valueOf()),
      y: ensureValidCoordinate(top.valueOf()),
      width: 20,
      height: 20,
    };

    dispatch(setSelectedBookable(null));
    dispatch(setCurrentBookableMarker(newBookableMarker));
    normalizeMarkers();
  };

  const handleMarkerClick = (itemNumber: number) => {
    const newSelectedBookable = currentBookables.bookables[itemNumber];
    dispatch(setSelectedBookable(newSelectedBookable));
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setImageName(e.target.files[0].name);
    dispatch(uploadFloorPlan(floor.office_id, floor.id, e.target.files[0]));
  };

  const getUploadBtnText = () => {
    const btnText = floor.floorplan_url
      ? t('spaceloop.floors.change_floorplan')
      : t('spaceloop.floors.upload_new_floorplan');
    return imageName === '' ? btnText : imageName;
  };

  useEffect(() => {
    normalizeMarkers();
  }, [currentBookables.bookables]);

  useEffect(() => {
    dispatch(getBookables({ floor: floor.id }));
  }, []);

  return <>
    <Typography id="server-modal-title" variant="h4" component="h2"
                paddingBottom={2}>{t('spaceloop.button.edit_bookables')}</Typography>
    {floor.floorplan_url && <>
      <Box
        component="div"
        style={{ overflow: 'auto', maxWidth: '100%', marginBottom: '10px' }}
        ref={inputEl}
      >
        <ImageMarker
          src={floor.floorplan_url}
          markers={markers}
          onAddMarker={(marker: Marker) => handleNewBookable(marker)}
          markerComponent={(props: MarkerComponentProps) => (
            <CustomMarker
              itemNumber={props.itemNumber}
              left={props.left}
              top={props.top}
              handleClick={() => handleMarkerClick(props.itemNumber as number)}
            />
          )}
        />
      </Box>
      <Divider />
    </>
    }
    <ContextMenu x={x} y={y} showMenu={showMenu} handleClose={handleMenuClose} bookableCounts={bookableCounts} />
    <Grid item flexDirection="row" display="flex">
      <Grid item flexDirection="row" justifyContent="flex-end" display="flex" flex={1}>
        <input
          color="primary"
          accept="image/*"
          type="file"
          id="icon-button-file"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        <label htmlFor="icon-button-file">
          <Button variant="primary" component="span" size="large" style={{ marginRight: '5px' }}>
            {getUploadBtnText()}
          </Button>
        </label>
      </Grid>
    </Grid>
  </>;
};
