import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Floor } from '../../../interfaces/floor.interface';

export interface FloorState {
  officeId: string;
  selectedFloorId: string;
  floorModalOpen: boolean;
  floors: Array<Floor>;
  floorLoading: boolean;
}

export const floorSlice = createSlice({
  name: 'floor',
  initialState: {
    officeId: '',
    selectedFloorId: '',
    floorModalOpen: false,
    floors: [],
    floorLoading: false,
  },
  reducers: {
    floorLoading: state => {
      state.floorLoading = true;
    },
    floorNotLoading: state => {
      state.floorLoading = false;
    },
    floorModalOpen: state => {
      state.floorModalOpen = true;
    },
    floorModalClose: state => {
      state.floorModalOpen = false;
    },
    setFloors: (state, action) => {
      state.floors = action.payload;
    },
    setOfficeId: (state, action) => {
      state.officeId = action.payload;
    },
    setSelectedFloorId: (state, action) => {
      state.selectedFloorId = action.payload;
    },
  },
});

export const {
  floorLoading,
  floorNotLoading,
  floorModalOpen,
  floorModalClose,
  setFloors,
  setOfficeId,
  setSelectedFloorId,
} = floorSlice.actions;

export const selectFloor = (state: RootState) => state.floor;

export default floorSlice.reducer;
