import { AnyAction, Dispatch } from 'redux';
import { getBookingClient } from '../../../api/api-client';
import { BookableFilterParams, BookableParams } from '../../../interfaces/bookable.interface';
import {
  bookableLoading,
  bookableNotLoading,
  setBookables,
  setCurrentBookableMarker,
  setSelectedBookable,
} from './bookableSlice';
import { AxiosResponse } from 'axios';

export const getBookables =
  (params?: Partial<BookableFilterParams>) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(bookableLoading());
    return getBookingClient()
      .get('api/bookables', {
        params: {
          filter: params,
        },
        paramsSerializer: {
          dots: true,
        },
      })
      .then(response => {
        dispatch(setBookables(response.data));
        dispatch(bookableNotLoading());
      });
  };

export const addBookable =
  (bookable: BookableParams, params?: Partial<BookableFilterParams>) =>
  (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
    dispatch(bookableLoading());
    return getBookingClient()
      .post('/api/bookables', bookable)
      .then(rsp => {
        dispatch(getBookables(params) as any as AnyAction);
        dispatch(setCurrentBookableMarker({}));
        dispatch(setSelectedBookable(null));
        dispatch(bookableNotLoading());

        return rsp
      });
  };

export const updateBookable =
  (bookable: Partial<BookableParams>, id: string, params?: Partial<BookableFilterParams>) =>
  (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
    dispatch(bookableLoading());
    return getBookingClient()
      .patch(`/api/bookables/${id}`, bookable)
      .then(rsp => {
        dispatch(getBookables(params) as any as AnyAction);
        dispatch(setCurrentBookableMarker({}));
        dispatch(setSelectedBookable(null));
        dispatch(bookableNotLoading());

        return rsp
      });
  };

export const deleteBookable =
  (id: string, params?: Partial<BookableFilterParams>) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(bookableLoading());
    return getBookingClient()
      .delete(`/api/bookables/${id}`)
      .then(() => {
        dispatch(getBookables(params) as any as AnyAction);
        dispatch(setCurrentBookableMarker({}));
        dispatch(setSelectedBookable(null));
        dispatch(bookableNotLoading());
      });
  };
