import queryString from 'query-string';

import { Attribute } from '../interfaces/attributes.interface';
import { SessionUser, SystemLocales } from '../interfaces/session.types';
import { Company } from '../interfaces/company.interface';

import { getContentLanguageAttribute, getSystemLanguageAttribute } from './attributes';
import { RootState } from '../app/store';

export function getAvailableSystemLanguages(state: RootState) {
  return getAvailableSystemLocales(state).map(locale => locale.split('_')[0]);
}

export function getAvailableSystemLocales(state: RootState) {
  return Object.keys(state.session.systemLocales);
}

export function getSystemLanguage(state: RootState) {
  const sysLocal = getSystemLocale(state);
  if (typeof sysLocal === 'string') {
    return sysLocal.split('_')[0];
  }
}

export function getDefaultContentLanguage(state: RootState) {
  return getDefaultContentLocale(state).split('_')[0];
}

export function getDefaultContentLocale(state: RootState) {
  return (
    localeFromCompany(getContentLanguageAttribute(state), state.session.company) ||
    state.session.defaultContentLocale
  );
}

export function getContentLanguage(state: RootState) {
  const contentLocal = getContentLocale(state);
  if (typeof contentLocal === 'string') {
    return contentLocal.split('_')[0];
  }
}

export function getContentLocale(state: RootState) {
  return (
    localeFromUser(getContentLanguageAttribute(state), state.session.user) ||
    state.session.defaultContentLocale
  );
}

export function getBrowserLanguage() {
  return getBrowserLocale().split('_')[0];
}

export function getSystemLocale(state: RootState) {
  return (
    localeFromUser(getSystemLanguageAttribute(state), state.session.user) || state.session.defaultSystemLocale
  );
}

export function getCompanyContentLocale(state: RootState) {
  return (
    localeFromUser(getContentLanguageAttribute(state), state.session.company) ||
    state.session.defaultContentLocale
  );
}

export function getCompanySystemLocale(state: RootState) {
  return (
    localeFromUser(getSystemLanguageAttribute(state), state.session.company) ||
    state.session.defaultSystemLocale
  );
}

export function getDefaultSystemLanguage(state: RootState) {
  return getDefaultSystemLocale(state).split('_')[0];
}

export function getDefaultSystemLocale(state: RootState) {
  return state.session.defaultSystemLocale;
}

export function isSystemLocaleAllowed(state: RootState, locale: string) {
  const systemLocaleKeys = Object.keys(state.session.systemLocales);
  for (const i in systemLocaleKeys) {
    if (systemLocaleKeys[i] === locale) {
      return true;
    }
  }

  return false;
}

function localeFromUser(attribute: Attribute, user: SessionUser | Company | null): SystemLocales | '' {
  if (!attribute || !user) {
    return '';
  }
  if (
    user.setting_attributes &&
    Object.keys(user.setting_attributes).length > 0 &&
    user.setting_attributes[attribute.uuid]
  ) {
    return user.setting_attributes[attribute.uuid];
  }

  return '';
}

function localeFromCompany(attribute: Attribute, company: Company | null) {
  if (!attribute || !company) {
    return '';
  }
  if (
    company.setting_attributes &&
    Object.keys(company.setting_attributes).length > 0 &&
    company.setting_attributes[attribute.uuid]
  ) {
    return company.setting_attributes[attribute.uuid];
  }

  return '';
}

export function getBrowserTimezone(): string {
  return Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'not detected';
}

export function getBrowserLocale() {
  let locale =
    (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
    navigator.language; // All browsers
  locale = locale.replace(/-/g, '_');

  // todo check against available list
  // for  now just english or german

  return locale;
}

export function redeemTokenReturnedTokenErrorRedirect(location: Location) {
  const searchParams = queryString.parse(location.search);
  delete searchParams.auth_token;
  const searchParamsWithoutAuthToken = queryString.stringify(searchParams);

  return `${location.pathname}?${searchParamsWithoutAuthToken}`;
}

export function authTokenUsedButNoManageMeSetRedirect(location: Location) {
  const searchParams = queryString.parse(location.search);
  delete searchParams.auth_token;
  const searchParamsWithoutAuthToken = queryString.stringify(searchParams);
  const redirect = `${location.pathname}?${searchParamsWithoutAuthToken}`;

  return `/password${location.search}&location=${redirect}&secureAccount=1`;
}
