/* eslint-disable @typescript-eslint/no-unused-vars */
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { getBookingClient } from '../../../api/api-client';
import { FloorParams } from '../../../interfaces/floor.interface';
import {
  floorLoading,
  floorModalClose,
  floorNotLoading,
  setFloors,
  setOfficeId,
  setSelectedFloorId,
} from './floorSlice';
import { AxiosResponse } from 'axios';

export const getFloors =
  (officeId: string) =>
    (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
      dispatch(floorLoading());
      return getBookingClient()
        .get(`/api/floors?filter.office=${officeId}`)
        .then(response => {
          dispatch(setFloors(response.data));
          dispatch(setOfficeId(officeId));
          dispatch(floorNotLoading());

          return response
        });
    };

export const addFloor =
  (floor: FloorParams, navigate: NavigateFunction) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> => {
      dispatch(floorLoading());
      return getBookingClient()
        .post('/api/floors', floor)
        .then(({ data }) => {
          dispatch(getFloors(floor.office) as any as AnyAction);
          dispatch(setActiveFloor(floor.office, data.id, navigate) as any as AnyAction);
        });
    };

export const updateFloor =
  (floor: Partial<FloorParams>, id: string, navigate: NavigateFunction) =>
    (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
      return getBookingClient()
        .patch(`/api/floors/${id}`, floor)
        .then((rsp) => {
          if (floor.office) {
            dispatch(getFloors(floor.office) as any as AnyAction);
            dispatch(setActiveFloor(floor.office, id, navigate) as any);
          }

          return rsp;
        });
    };

export const uploadFloorPlan =
  (officeId: string, floorId: string, image: any) =>
    (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
      dispatch(floorLoading());
      return getBookingClient()
        .post(`/api/floors/${floorId}/floorplan`, image, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
        .then(async (rsp) => {
          await dispatch(getFloors(officeId) as any as AnyAction);

          return rsp;
        });
    };

export const deleteFloor =
  (officeId: string, id: string) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> => {
      dispatch(floorLoading());
      return getBookingClient()
        .delete(`/api/floors/${id}`)
        .then(() => {
          dispatch(getFloors(officeId) as any as AnyAction);
          dispatch(floorNotLoading());
        });
    };

export const setActiveFloor =
  (officeId: string, floorId: string, navigate?: NavigateFunction) =>
    (dispatch: Dispatch<AnyAction>): void => {
      dispatch(floorLoading());
      dispatch(setSelectedFloorId(floorId));
      if (navigate) {
        navigate(`/offices/${officeId}/floors/${floorId}`);
      }
      dispatch(floorNotLoading());
    };
