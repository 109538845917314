import _ from 'lodash';
import { Store } from 'redux';

import {
  getBrowserLocale,
  getCompanyContentLocale,
  getCompanySystemLocale,
  getContentLocale,
  getDefaultContentLocale,
  getSystemLocale,
} from './session';
import { ContentLocales } from '../interfaces/session.types';

let translationLocales: string[] = [];

export function subscribeContentLanguageToStore(store: Store) {
  store.subscribe(() => {
    const state = store.getState();
    translationLocales = [
      getContentLocale(state),
      getSystemLocale(state),
      getCompanyContentLocale(state),
      getCompanySystemLocale(state),
      getDefaultContentLocale(state),
      getBrowserLocale(),
    ];
  });
}

export function translateContent(translationObject?: ContentLocales) {
  if (translationObject === null || translationObject === undefined) {
    return '';
  }
  const filtered = _.pickBy(translationObject);

  for (const locale of translationLocales) {
    if (translationObject[locale] && translationObject[locale] !== '') {
      return translationObject[locale];
    }
  }

  if (translationObject[Object.keys(filtered)[0]] && translationObject[Object.keys(filtered)[0]] !== '') {
    return translationObject[Object.keys(filtered)[0]];
  }

  return '';
}
