/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectBooking } from '../../store/booking/bookingSlice';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  selectBookingFloor,
  selectBookingFrom,
  selectBookingUntil,
  validateTimeslot,
} from '../../store/booking/actions';
import { useNotifications } from '../../../hooks/useNotifications';
import dayjs, { Dayjs } from 'dayjs';
import { Floor } from '../../../interfaces/floor.interface';
import { selectFloor } from '../../store/floor/floorSlice';
import { translateContent } from '../../../services/translationServices';
import { getFloors } from '../../store/floor/action';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import { Box } from '@mui/system';
import { CustomMarker } from '../../../components/marker/CustomMarker';
import { getBookables } from '../../store/bookable/actions';
import { BookableFilterParams } from '../../../interfaces/bookable.interface';
import { BookableState, resetContext, selectBookable, setSelectedBookable } from '../../store/bookable/bookableSlice';
import { SystemLocalesEnum } from '../../../interfaces/session.types';
import { getCompanyValueBySlug } from '../../../services/attributes';
import { RootState, store } from '../../store';
import { AttrSlugType } from '../../../interfaces/attributes.interface';
import { BookingParams } from '../../../interfaces/bookings.interface';
import { addBooking } from '../../store/bookings/action';
import { selectSession, SessionState } from '../../store/session/sessionSlice';
import { Page } from '../../../components/loadable/Page';
import { sprintf } from 'sprintf-js';
import { AxiosError } from 'axios';
import { ApiError } from '../../../interfaces/api.interface';

export const FloorSelect = (): ReactElement => {
  const currentBooking = useSelector(selectBooking);
  const currentBookables: BookableState = useSelector(selectBookable);
  const currentAllFloors: Array<Floor> = useSelector(selectFloor).floors;
  const currentSession: SessionState = useSelector(selectSession);
  const floor = currentBooking.floor;

  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { showError, showSuccess, showApiError } = useNotifications();
  const [isExpended, setIsExpended] = useState(true);
  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );

  useEffect(() => {
    if (currentBooking.office) dispatch(getFloors(currentBooking.office?.id));
  }, []);

  useEffect(() => {
    if (!currentBooking.floor || !currentBooking.from || !currentBooking.until || !currentBooking.bookableType) {
      return;
    }
    const queryParams: Partial<BookableFilterParams> = {
      floor: currentBooking.floor.id,
      unoccupied: true,
      available_from: new Date(currentBooking.from),
      available_until: new Date(currentBooking.until),
      type: currentBooking.bookableType,
      enabled: true,
    };
    dispatch(getBookables(queryParams));
    setIsExpended(false);
  }, [currentBooking]);

  useEffect(() => {
    normalizeMarkers();
  }, [currentBookables.bookables]);

  const checkTime = () => {
    const from = currentBooking.from ? dayjs(currentBooking.from) : null;
    const until = currentBooking.until ? dayjs(currentBooking.until) : null;

    if (from === null || until === null || from.isBefore(until)) {
      dispatch(validateTimeslot(true));

      return;
    }
    dispatch(selectBookingFloor(null));
    dispatch(validateTimeslot(false));
    showError('From must be before until');
  };

  const handleExpandToggle = (force?: boolean) => {
    if (force || (!!currentBooking.floor?.id && !!currentBooking.from && !!currentBooking.until)) {
      setIsExpended(!isExpended);
    }
  };

  const goBack = () => {
    dispatch(selectBookingFloor(null));
    navigate('/booking/init');
  };
  const handleFromChange = (from: Dayjs | null) => {
    // TODO - Datetimepicker seems to use the local timezone. So currently, we store utc time to redux
    // to get rid of the timezone, but the picker uses the local timezone
    dispatch(selectBookingFrom(from ? from.utc(true).toISOString() : null));
  };
  const handleUntilChange = (until: Dayjs | null) => {
    dispatch(selectBookingUntil(until ? until.utc(true).toISOString() : null));
  };

  useEffect(() => {
    if (!currentBooking.office || !currentBooking.bookableType) {
      goBack();
    }
  });

  const setFloor = (event: SelectChangeEvent) => {
    const floorId = event.target.value as string;
    if (floorId === '') {
      return;
    }
    dispatch(selectBookingFloor(currentAllFloors.find(floor => floor.id == floorId) as Floor));
  };

  const handleMarkerClick = (itemNumber: number) => {
    const newSelectedBookable = currentBookables.bookables[itemNumber];
    dispatch(setSelectedBookable(newSelectedBookable));
    setModalOpen(true);
  };

  const normalizeMarkers = () => {
    if (floor) {
      setMarkers(currentBookables.bookables.map(({ x, y }) => ({ left: x, top: y })));
    }
  };

  const handleModalClose = () => {
    dispatch(resetContext());
    setModalOpen(false);
  };

  const handleBooking = () => {
    if (currentBookables) {
      const newBooking: BookingParams = {
        bookable_id: currentBookables.selectedBookable?.id ?? '',
        start: currentBooking.from ?? '',
        until: currentBooking.until ?? '',
        user_id: currentSession.user?.uuid ?? '',
      };
      dispatch(addBooking(newBooking))
        .then(() => {
          showSuccess(t('spaceloop.booking.successfull') as string);
          navigate(`/bookings/${currentSession.user?.uuid}`);
        })
        .catch((err: AxiosError) => {
          err.response && showApiError(err.response.data as ApiError[]);
        });
    }
    setModalOpen(false);
  };

  return <Page>
    <Grid item xs={11} md={8}>
      <Accordion expanded={isExpended}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => handleExpandToggle(true)}
        >
          {' '}
          <Typography>{t('spaceloop.booking.change_selection')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="column">
              <DateTimePicker
                renderInput={props => <TextField {...props} />}
                label={t('spaceloop.booking.from')}
                value={
                  // Unfortunately, the following line is necessary, because DateTimePicker is taking the timezone
                  // into account, while we don't in the store and backend. If we would simply use currentBooking.from
                  // DateTimePicker would show the date including the offset of the local timezone
                  currentBooking.from ? dayjs(currentBooking.from).utc(false) : null
                }
                onChange={handleFromChange}
                ampm={false}
                views={['day', 'hours', 'minutes']}
                minutesStep={15}
                onAccept={checkTime}
              />
              <DateTimePicker
                renderInput={props => <TextField {...props} />}
                label={t('spaceloop.booking.until')}
                value={currentBooking.until ? dayjs(currentBooking.until).utc(false) : null}
                onChange={handleUntilChange}
                ampm={false}
                views={['day', 'hours', 'minutes']}
                minutesStep={15}
                onAccept={checkTime}
              />
              <FormControl fullWidth>
                <InputLabel id="select-booking-floor">Select Floor</InputLabel>
                <Select
                  onChange={setFloor}
                  value={currentBooking.floor?.id || ''}
                  labelId="select-booking-floor"
                  disabled={!currentBooking.timeValid}
                >
                  {currentAllFloors
                    .filter(item => item.floorplan_url !== null)
                    .map((floor, i) => (
                      <MenuItem value={floor.id} key={i}>
                        {translateContent(floor.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </LocalizationProvider>
        </AccordionDetails>
      </Accordion>
      {!!currentBooking.floor?.floorplan_url && (
        <Box
          component="div"
          sx={{
            overflow: 'auto',
            justifyContent: 'center',
            background: 'white',
            padding: '10px',
          }}
        >
          <ImageMarker
            src={currentBooking.floor?.floorplan_url}
            markers={markers}
            markerComponent={(props: MarkerComponentProps) => (
              <CustomMarker
                itemNumber={props.itemNumber}
                left={props.left}
                top={props.top}
                handleClick={() => handleMarkerClick(props.itemNumber as number)}
              />
            )}
          />
        </Box>
      )}
      <Grid item>
        <Button variant="secondary" onClick={goBack}>
          {t('button.go_back')}
        </Button>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {sprintf(t('spaceloop.booking.modal_title'), t('booking.bookable_type_' + currentBooking.bookableType))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {sprintf(t('spaceloop.booking.modal_content'), translateContent(currentBookables.selectedBookable?.name))}
          </DialogContentText>
          <Grid item style={{ marginTop: 10, flexDirection: 'row' }}>
            {currentBookables.selectedBookable?.amenities.map(item => (
              <Chip style={{ margin: 2 }} key={item.id} label={item.name[companyLanguage]} />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={() => handleModalClose()}>{t('spaceloop.button.cancel')}</Button>
          <Button variant="primary" onClick={() => handleBooking()} autoFocus>{t('spaceloop.button.book')}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  </Page>;
};
