import { AnyAction, Dispatch } from 'redux';
import { RootState } from '..';
import { getUserClient } from '../../../api/api-client';
import { Attribute, AttrInternalType, AttrSlugType } from '../../../interfaces/attributes.interface';
import { getAllAttributesFailed, getAllAttributesRequest, getAllAttributesSuccess } from './attributeSlice';

export const fetchAttributesList = () =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(getAllAttributesRequest());
    return getUserClient()
      .get('/attributes')
      .then(response => {
        dispatch(getAllAttributesSuccess(response.data));
      })
      .catch(error => {
        dispatch(getAllAttributesFailed(error));
      });
  };

export function getAttribute(state: RootState, id: string) {
  const byId = state.attribute.byId as Attribute;
  type ObjectKey = keyof typeof byId;
  return byId[id as ObjectKey];
}

export function getAttributes({ attribute }: RootState, internalTypeList?: AttrInternalType[]): Attribute[] {
  return Object.values(attribute.byId as Attribute).reduce((acc: Attribute[], attribute: Attribute) => {
    if (internalTypeList === undefined || internalTypeList.includes(attribute.internal_type)) {
      acc.push(attribute);
    }
    return acc;
  }, []);
}

export function getAttributeBySlug(state: RootState, slug: AttrSlugType): Attribute {
  return getAttributes(state).find((attr: Attribute) => attr.slug === slug) as Attribute;
}
