import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
export default function PasswordIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
          <rect width="18" height="14" x="4" y="9" rx="4" />
          <circle cx="13" cy="17" r="2" />
          <path d="M8 9V7.266C8 4.362 10.243 2 13 2s5 2.362 5 5.266V9" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M13 13v1.5" />
        </g>
      </svg>
    </SvgIcon>
  );
}
