import { Office } from '../../../interfaces/office.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Floor } from '../../../interfaces/floor.interface';

export interface BookingState {
  office: Office | null;
  floor: Floor | null;
  bookableType: string | null;
  from: string | null;
  until: string | null;
  timeValid: boolean;
}

const initialState: BookingState = {
  office: null,
  floor: null,
  bookableType: null,
  from: null,
  until: null,
  timeValid: false,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    officeSelected: (state, action: PayloadAction<Office>) => {
      state.office = action.payload;
    },
    floorSelected: (state, action: PayloadAction<Floor | null>) => {
      state.floor = action ? action.payload : null;
    },
    bookableTypeSelected: (state, action: PayloadAction<string>) => {
      state.bookableType = action.payload;
    },
    fromSelected: (state, action: PayloadAction<string | null>) => {
      state.from = action.payload;
    },
    untilSelected: (state, action: PayloadAction<string | null>) => {
      state.until = action.payload;
    },
    setTimeslotValid: (state, action: PayloadAction<boolean>) => {
      state.timeValid = action.payload;
    },
    resetBookingForm: () => initialState,
  },
});

export const {
  officeSelected,
  floorSelected,
  bookableTypeSelected,
  fromSelected,
  untilSelected,
  resetBookingForm,
  setTimeslotValid,
} = bookingSlice.actions;

export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
