import { Amenity } from './amenity.interface';

export interface BookableType {
  slug: BookableTypeJunction;
}

export type BookableTypeJunction = 'desk' | 'meeting_room' | 'custom'

export const BookableTypes: BookableType[] = [
  {
    slug: 'desk',
  },
  {
    slug: 'meeting_room',
  },
  {
    slug: 'custom',
  },
];

import { SystemLocales, SystemLocalesEnum } from './session.types';

export interface Bookable {
  id: string;
  floor_id: string;
  neigborhood_id: string;
  occupant_id: string;
  name: SystemLocales;
  type: BookableTypeJunction;
  x: number;
  y: number;
  width: number;
  height: number;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  amenities: Array<Amenity>;
}

export interface BookableParams {
  floor_id: string;
  neigborhood: string | null;
  occupant: string | null;
  name: { [key in keyof typeof SystemLocalesEnum]?: string };
  type: string;
  box: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  enabled: boolean;
  amenities: Array<string>;
}

export type BookableFilterParams = {
  id: string;
  floor: string;
  available_from: Date;
  available_until: Date;
  occupant: string;
  unoccupied: boolean;
  type: string;
  enabled: boolean;
};
