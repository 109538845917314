import { User } from './user.interface';

export interface Timezones {
  [key: string]: string;
}

export interface ContentLocales {
  [key: string]: string;
}

export enum SystemLocalesEnum {
  DeDe = 'de_DE',
  EnUs = 'en_US',
}

export type SystemLocales = { [key in SystemLocalesEnum]: string };

export interface SessionUser {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  external: boolean;
  deactivated: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  framework: boolean;
  imageUrl: string | null;
  userPermissions: UserPermissions[];
  setting_attributes: SessionUserSettingsAttrs;
  manager_attributes: { [key: string]: User | null };
  isManager: boolean;
}

export interface SessionUserSettingsAttrs {
  [key: string]: string | null;
}

export enum UserPermissions {
  ManageMe = 'manage_me',
  ManageCompany = 'manage_company',
  ManageUsers = 'manage_users',
}

export interface LoginUserCredentials {
  identifier: string;
  password: string;
}

export interface SignupParams {
  companyName: string;
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  utm_source?: string;
}
