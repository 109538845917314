import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { toInteger } from 'lodash';
import { BookableParams, BookableTypeJunction, BookableTypes } from '../../interfaces/bookable.interface';
import { useDispatch, useSelector } from 'react-redux';
import { addAmenity, getAmenities } from '../../app/store/amenity/actions';
import { AmenityState, selectAmenity } from '../../app/store/amenity/amenitySlice';
import { SystemLocalesEnum } from '../../interfaces/session.types';
import { getCompanyValueBySlug } from '../../services/attributes';
import { RootState, store } from '../../app/store';
import { AttrSlugType } from '../../interfaces/attributes.interface';
import { addBookable, deleteBookable, updateBookable } from '../../app/store/bookable/actions';
import { FloorState, selectFloor } from '../../app/store/floor/floorSlice';
import { BookableState, resetContext, selectBookable } from '../../app/store/bookable/bookableSlice';
import AddIcon from '@mui/icons-material/Add';
import { AxiosError } from 'axios';
import { useNotifications } from '../../hooks/useNotifications';
import { ApiError } from '../../interfaces/api.interface';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { translateContent } from '../../services/translationServices';

interface ContextMenuProps {
  x: string | number;
  y: string | number;
  showMenu: boolean;
  handleClose: () => void;
  bookableCounts: Record<BookableTypeJunction, number>;
}

export const ContextMenu = (props: ContextMenuProps): React.ReactElement => {
  const [t] = useTranslation();
  const currentAmenity: AmenityState = useSelector(selectAmenity);
  const currentFloor: FloorState = useSelector(selectFloor);
  const currentBookables: BookableState = useSelector(selectBookable);

  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );

  const dispatch = useDispatch<any>();
  const { selectedBookable, currentBookableMarker } = currentBookables;

  const [menuRef, setMenuRef] = useState<HTMLDivElement>();
  const [offset, setOffset] = useState({ x: 30, y: -30 });
  const [name, setName] = useState('');
  const [type, setType] = useState('desk');
  // const [neigborhood, setNeigborhood] = useState('');
  // const [occupant, setOccupant] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [amenities, setAmenities] = useState<Array<string>>([]);
  const [amenityValue, setAmenityValue] = useState('');
  const [nameChanged, setNameChanged] = useState<boolean>(false);
  const availableAmenityNames = currentAmenity.amenities
    .map(item => translateContent(item.name))
    .filter(item => amenities.indexOf(item) === -1);
  const { showApiError } = useNotifications();

  const resetForm = () => {
    setName(autoGenerateTitle('desk'));
    setType('desk');
    // setNeigborhood('');
    // setOccupant('');
    setIsEnabled(true);
    setNameChanged(false);
    setAmenities([]);
    setAmenityValue('');
  };

  useEffect(() => {
    if (selectedBookable) {
      setName(translateContent(selectedBookable?.name));
      setType(selectedBookable?.type);
      // setNeigborhood(selectedBookable?.neigborhood_id);
      // setOccupant(selectedBookable?.occupant_id);
      setAmenities(selectedBookable.amenities.map(amenity => translateContent(amenity.name)));
      setIsEnabled(selectedBookable?.enabled);
    } else {
      resetForm();
    }
  }, [selectedBookable, currentBookableMarker]);
  const [canBeAdded, setCanBeAdded] = useState<boolean>(false);

  useEffect(() => {
    // recalculate menu offset
    if (!menuRef || !props.showMenu) return;

    const menu = menuRef.getElementsByClassName('MuiPaper-root')[0] as HTMLElement;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const width = menu?.offsetWidth;
    const height = menu?.offsetHeight;
    const x = toInteger(props.x);
    const y = toInteger(props.y);

    const minYOffset = -30;
    const minXOffset = 30;
    const newOffset = { x: minXOffset, y: minYOffset };
    if (y + height > windowHeight) {
      newOffset.y = windowHeight - (y + height) + minYOffset;
    }
    if (x + width + minXOffset > windowWidth) {
      newOffset.x = windowWidth - (x + width + minXOffset);
    }

    setOffset(newOffset);
  }, [menuRef, props.x, props.y, props.showMenu, amenities]);

  const autoGenerateTitle = (type: BookableTypeJunction) =>
    `${t(`booking.bookable_type_${type}`)} ${props.bookableCounts[type] + 1}`;


  const callbackRef = useCallback((node: any) => {
    if (node !== null) {
      setMenuRef(node);
    }
  }, []);

  const onAddDeskAmenity = () => {
    setAmenities([...amenities, amenityValue]);
    dispatch(
      addAmenity({
        name: {
          [companyLanguage]: amenityValue,
        },
      }),
    ).then(() => {
      setAmenityValue('')
    });
  };

  useEffect(() => {
    dispatch(getAmenities());
  }, []);

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameChanged(true);
  };

  const handleTypeChanged = (e: SelectChangeEvent) => {
    setType(e.target.value);
    if (!nameChanged) {
      setName(autoGenerateTitle(e.target.value as BookableTypeJunction));
    }
  };

  const handleSave = () => {
    if (currentBookables.selectedBookable) {
      const editedBookable: Partial<BookableParams> = {
        floor_id: currentFloor.selectedFloorId,
        neigborhood: null,
        occupant: null,
        name: {
          [companyLanguage]: name,
        },
        type,
        enabled: isEnabled,
        amenities: amenities.map(
          amenity => currentAmenity.amenities.find(item => item.name[companyLanguage] === amenity)?.id || '',
        ),
      };
      dispatch(updateBookable(
        editedBookable,
        currentBookables.selectedBookable?.id,
        { floor: currentFloor.selectedFloorId },
      ))
        .then(() => {
          handleContextMenuClose();
        })
        .catch((rsp: AxiosError) => {
          showApiError(rsp.response?.data as ApiError[]);
        });

      return;
    }

    const { x, y, width, height } = currentBookables.currentBookableMarker;
    if (x === undefined || y === undefined || width === undefined || height === undefined) return;
    const newBookable: BookableParams = {
      floor_id: currentFloor.selectedFloorId,
      neigborhood: null,
      occupant: null,
      name: { [companyLanguage]: name },
      type,
      box: { x, y, width, height },
      enabled: isEnabled,
      amenities: amenities.map(
        amenity => currentAmenity.amenities.find(item => translateContent(item.name) === amenity)?.id || '',
      ),
    };
    dispatch(addBookable(newBookable, { floor: currentFloor.selectedFloorId }))
      .then(() => {
        handleContextMenuClose();
      })
      .catch((rsp: AxiosError) => {
        showApiError(rsp.response?.data as ApiError[]);
      });
  };

  const handleDelete = () => {
    if (currentBookables.selectedBookable) {
      const queryParams = {
        floor: currentFloor.selectedFloorId,
      };

      dispatch(deleteBookable(currentBookables.selectedBookable.id, queryParams));
    }
    handleContextMenuClose();
  };

  const handleContextMenuClose = () => {
    dispatch(resetContext());
    resetForm();
    props.handleClose();
  };

  const onClose = () => {
    setAmenities([]);
    props.handleClose();
  };

  return (
    <Menu
      ref={callbackRef}
      sx={{
        '& .MuiPaper-root': {
          left: `${toInteger(props.x) + offset.x}px !important`,
          top: `${toInteger(props.y) + offset.y}px !important`,
        },
      }}
      open={props.showMenu}
      onClose={onClose}
    >
      <Stack onKeyDown={e => e.stopPropagation()} mx={2} mt={4} spacing={1.2} width={300}>
        <TextField
          label={t('spaceloop.bookables.name')}
          size="small"
          variant="outlined"
          required
          value={name}
          onChange={handleNameChanged}
        />

        <FormControl fullWidth>
          <InputLabel required>{t('spaceloop.bookables.select_type')}</InputLabel>
          <Select value={type} label="Select type" onChange={handleTypeChanged}>
            {BookableTypes.map(type => (
              <MenuItem key={type.slug} value={type.slug}>
                {t('booking.bookable_type_' + type.slug)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*<TextField*/}
        {/*  label="Neigborhood"*/}
        {/*  variant="outlined"*/}
        {/*  value={neigborhood}*/}
        {/*  onChange={e => setNeigborhood(e.target.value as string)}*/}
        {/*/>*/}
        {/*<TextField*/}
        {/*  label="Assign desk"*/}
        {/*  variant="outlined"*/}
        {/*  value={occupant}*/}
        {/*  onChange={e => setOccupant(e.target.value as string)}*/}
        {/*/>*/}

        <Stack justifyContent="flex-end">
          <Autocomplete
            disablePortal
            options={availableAmenityNames}
            fullWidth
            freeSolo
            value={amenityValue}
            onInputChange={(e, value) => {
              value = value.trim()
              setAmenityValue(value);
              const matchedOptions = availableAmenityNames.filter(item => item.startsWith(value));
              if (!matchedOptions.length && amenities.indexOf(value) === -1) setCanBeAdded(true);
              else setCanBeAdded(false);
            }}
            onChange={(e, value, reason) => {
              if (reason !== 'selectOption') return;
              value = value.trim();
              if (amenities.indexOf(value) === -1) {
                setAmenities([...amenities, value]);
                setAmenityValue('')
              }
            }}
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label={t('spaceloop.bookables.amenities')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: canBeAdded ? (
                    <InputAdornment position="end">
                      <IconButton onClick={onAddDeskAmenity}>
                        <AddIcon htmlColor="#4E546B" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
          <Stack direction="row" flexWrap="wrap" gap={0.5}>
            {amenities.map((amenity, index) => (
              <Chip
                key={`${amenity}-${index}`}
                label={amenity}
                onDelete={() => {
                  const newAmenities = amenities.slice();
                  newAmenities.splice(index, 1);
                  setAmenities(newAmenities);
                }}
              />
            ))}
          </Stack>
        </Stack>

        <FormControl fullWidth>
          <FormControlLabel
            control={<Switch checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
            label={t(isEnabled ? 'spaceloop.bookables.enabled' : 'spaceloop.bookables.disabled')}
          />
        </FormControl>

        <Grid item flexDirection="row" display="flex">
          {currentBookables.selectedBookable?.id && (
            <Grid item flexDirection="row" justifyContent="flex-start" display="flex" flex={1}>
              <IconButton aria-label="delete" onClick={handleDelete}><DeleteIcon /></IconButton>
            </Grid>
          )}

          <Grid item flexDirection="row" justifyContent="flex-end" display="flex" flex={1}>
            <Button variant="secondary" onClick={props.handleClose}>Cancel</Button>
            <Button variant="primary" onClick={handleSave} style={{ marginLeft: 5 }}>Save</Button>
          </Grid>
        </Grid>
      </Stack>
    </Menu>
  );
};
