import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { getBookingClient } from '../../../api/api-client';
import { AmenityParams } from '../../../interfaces/amenity.interface';
import { amenityLoading, amenityNotLoading, setAmenities } from './amenitySlice';
import { AxiosResponse } from 'axios';

export const getAmenities =
  () =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(amenityLoading());
    return getBookingClient()
      .get('api/amenities')
      .then(response => {
        dispatch(setAmenities(response.data));
        dispatch(amenityNotLoading());
      });
  };

export const addAmenity =
  (amenity: AmenityParams) =>
  (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
    dispatch(amenityLoading());
    return getBookingClient()
      .post('/api/amenities', amenity)
      .then(rsp => {
        dispatch(getAmenities() as any as AnyAction);
        dispatch(amenityNotLoading());

        return rsp
      });
  };

export const updateAmenity =
  (amenity: Partial<AmenityParams>, id: string) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(amenityLoading());
    return getBookingClient()
      .patch(`/api/amenities/${id}`, amenity)
      .then(() => {
        dispatch(getAmenities() as any as AnyAction);
        dispatch(amenityNotLoading());
      });
  };

export const deleteAmenity =
  (id: string) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(amenityLoading());
    return getBookingClient()
      .delete(`/api/amenities/${id}`)
      .then(() => {
        dispatch(getAmenities() as any as AnyAction);
        dispatch(amenityNotLoading());
      });
  };
