import React, { useRef, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { floorModalClose, FloorState, selectFloor } from '../../app/store/floor/floorSlice';
import { useDispatch } from 'react-redux';

import { FloorParams } from '../../interfaces/floor.interface';
import { RootState, store } from '../../app/store';
import { getCompanyValueBySlug } from '../../services/attributes';
import { AttrSlugType } from '../../interfaces/attributes.interface';
import { addFloor } from '../../app/store/floor/action';
import { SystemLocalesEnum } from '../../interfaces/session.types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FloorModal(): React.ReactElement {
  const currentFloor: FloorState = useSelector(selectFloor);
  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>('');
  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );

  const navigate = useNavigate();

  const handleCreateFloor = () => {
    const newFloor: FloorParams = {
      name: {
        [companyLanguage]: name,
      },
      office: currentFloor.officeId,
    };
    dispatch(addFloor(newFloor, navigate)).then(() => {
      dispatch(floorModalClose());
    });
  };

  return (
    <Modal
      onClose={() => dispatch(floorModalClose())}
      open={currentFloor.floorModalOpen}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      container={() => rootRef.current}
    >
      <Box
        sx={{
          position: 'relative',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #fff',
          borderRadius: 2,
          boxShadow: theme => theme.shadows[5],
          p: 4,
        }}
      >
        <Typography id="server-modal-title" variant="h4" component="h2" paddingBottom={2}>{t('spaceloop.floors.create_new_floor')}</Typography>
        <TextField
          label="name"
          type="name"
          variant="outlined"
          fullWidth
          id="nameInput"
          onChange={event => setName(event.target.value)}
        />
        <Grid item flexDirection="row" justifyContent="flex-end" display="flex">
          <Button
            variant="primary"
            onClick={() => {
              handleCreateFloor();
            }}
            color="error"
          >
            Create
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
