import React, { FC, ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void
  onConfirm: () => void
  open?: boolean;
  title: string;
  text: string;
}

export const DeleteDialog: FC<Props> = (props): ReactElement => {
  const [t] = useTranslation();

  return <Dialog
    open={!!props.open}
    onClose={props.onClose}
    fullWidth
  >
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} variant="secondary">{t('spaceloop.button.cancel')}</Button>
      <Button onClick={props.onConfirm} autoFocus variant="primary">{t('spaceloop.button.delete')}</Button>
    </DialogActions>
  </Dialog>
}
