import { Fab, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { MarkerComponentProps } from 'react-image-marker';
import { BookableState, selectBookable } from '../../app/store/bookable/bookableSlice';
import { useSelector } from 'react-redux';
import { SystemLocalesEnum } from '../../interfaces/session.types';
import { getCompanyValueBySlug } from '../../services/attributes';
import { RootState, store } from '../../app/store';
import { AttrSlugType } from '../../interfaces/attributes.interface';
import { DoorBack, QrCode2, TableRestaurant } from '@mui/icons-material';
import { translateContent } from '../../services/translationServices';

interface CustomMarkerProps extends MarkerComponentProps {
  handleClick: () => void;
}

export const CustomMarker = (props: CustomMarkerProps): React.ReactElement => {
  const currentBookables: BookableState = useSelector(selectBookable);
  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );
  const currentBookable = currentBookables.bookables[props.itemNumber as number];

  const getMarkerIcon = (): ReactElement => {
    if (!currentBookable) {
      return <></>;
    }
    switch (currentBookable.type) {
      case 'desk':
        return <TableRestaurant style={{ color: 'black', width: 35, height: 35 }} />;
      case 'meeting_room':
        return <DoorBack style={{ color: 'black', width: 35, height: 35 }} />;
      default:
        return <QrCode2 style={{ color: 'black', width: 35, height: 35 }} />;
    }
  };

  let tooltipText = `${currentBookable ? translateContent(currentBookable.name) : 'New Bookable'}`;
  if (currentBookable && currentBookable.amenities.length > 0) {
    tooltipText = `${tooltipText} with amenities: ${currentBookable.amenities
      .map(item => item.name[companyLanguage])
      .join(', ')}`;
  }
  return (
      <Fab style={{ transform: 'scale(0.6)', color: '#ebebeb', marginLeft: '-17px', marginTop: '-17px' }} onClick={props.handleClick}>
        <Tooltip
          title={tooltipText}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                fontSize: 15,
              },
            },
          }}
        >
          {getMarkerIcon()}
        </Tooltip>
      </Fab>
  );
};
