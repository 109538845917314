import { Store } from 'redux';
import i18next from 'i18next';
import moment from 'moment';
import llsAPI from 'axios';

import { asyncForEach } from './utils';

import { getBrowserLocale, getSystemLocale, isSystemLocaleAllowed } from './session';
import { apiConfig } from '../config/server.index';

const momentTranslations: { [key: string]: { [key: string]: string } } = {
  de: {
    future: 'in %s',
    past: '%s ago',
    s: 'wenigen Sekunden',
    ss: '%d Sekunden',
    m: '1 Minute',
    mm: '%d Minuten',
    h: '1 Stunde',
    hh: '%d Stunden',
    d: '1 Tag',
    dd: '%d Tage',
    M: '1 Monat',
    MM: '%d monate',
    y: '1 Jahr',
    yy: '%d Jahre',
  },
  en: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
};

class Translations {
  STORAGE_PREFIX_TRANSLATION = 'translation_';
  STORAGE_PREFIX_TRANSLATION_WRITE_TIME = 'translation_update_';
  lastLocale = '';
  store: null | Store = null;

  // Check status of translation keys, fetch if required
  async initTranslations(availableLanguages: string[]) {
    const start = async () => {
      await asyncForEach(availableLanguages, async language => {
        const hasChanges = await this.fetchTranslationsLastUpdate(language);
        if (hasChanges) {
          const fetchedTranslations = await this.fetchTranslations(language);
          this.storeTranslations(language, fetchedTranslations);
        }
      });
    };
    await start();
  }

  // Wait for translations to initialize, then configure i18next

  async init(availableLanguages: string[], language: string, defaultSystemLanguage: string, store: Store) {
    this.store = store;
    this.subscribeToStore(store);
    await this.initTranslations(availableLanguages);
    return this.initI18n(availableLanguages, language, defaultSystemLanguage);
  }

  initI18n(availableLanguages: string[], language: string, fallbackLanguage: string) {
    /** configure translation package */
    i18next.init({
      //whitelist: availableLanguages,
      fallbackLng: fallbackLanguage,
      lng: language,
      keySeparator: '/',
      // Otherwise we cannot use keys with an included colon. This happens at the userlist where we have group filters.
      // There the label key is dynamic cannot be translated by key. Since we need a colon at the label nonetheless
      // we have to add it there at the end of the "translation key" which leads to an empty translation.
      nsSeparator: '::',
      initImmediate: false,
      preload: availableLanguages,
      debug: apiConfig.debug,
      resources: availableLanguages.reduce((acc: { [key: string]: { translation: string } }, lang) => {
        acc[lang] = { translation: this.getStoredTranslations(lang) };
        return acc;
      }, {}),
      returnEmptyString: true,
      returnNull: true,
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format(value, format) {
          if (format === 'uppercase') return value.toUpperCase();
          return value;
        },
      },
    });

    return i18next;
  }

  getStoredTranslations(language: string) {
    const translation = localStorage.getItem(this.getLocalStorageTranslationKey(language));
    if (typeof translation === 'string') {
      try {
        return JSON.parse(translation);
      } catch {
        return null;
      }
    }
    return null;
  }

  getLocalStorageTranslationKey(language: string) {
    return this.STORAGE_PREFIX_TRANSLATION + language;
  }

  getLocalStorageLastUpdateKey(language: string) {
    return this.STORAGE_PREFIX_TRANSLATION_WRITE_TIME + language;
  }

  getStoredWriteTime(language: string) {
    const timeString = localStorage.getItem(this.getLocalStorageLastUpdateKey(language));
    if (timeString) {
      return JSON.parse(timeString);
    }
    return null;
  }

  storeTranslations(language: string, tmpTranslations: { [key: string]: string }) {
    localStorage.setItem(this.getLocalStorageTranslationKey(language), JSON.stringify(tmpTranslations));
    localStorage.setItem(this.getLocalStorageLastUpdateKey(language), JSON.stringify(moment().toJSON()));
  }

  fetchTranslations(language: string) {
    return llsAPI.get(this.getTranslationUrlForLanguage(language)).then(resp => resp.data);
  }

  getTranslationUrlForLanguage(language: string) {
    return `${apiConfig.translation_service.url}/api/translations/export/${language}/${
      apiConfig.translation_service.published ? 'published' : 'unpublished'
    }?tags=loop_x,deskloop,spaceloop`;
  }

  async fetchTranslationsLastUpdate(language: string) {
    return llsAPI.get(this.getTranslationLastUpdateUrlForLanguage(language)).then(resp => {
      if (resp.data) {
        const localLastUpdate = this.getStoredWriteTime(language);
        const storedTranslation = this.getStoredTranslations(language);
        if (!storedTranslation || localLastUpdate === undefined || localLastUpdate === null) {
          return true;
        }
        const lastServerUpdate = moment(this.cutDateString(resp.data), '');
        const lastLocalUpdate = moment(this.cutDateString(localLastUpdate));
        if (lastServerUpdate.isAfter(lastLocalUpdate)) {
          return true;
        }
      }
      return false;
    });
  }

  getTranslationLastUpdateUrlForLanguage(language: string) {
    return `${apiConfig.translation_service.url}/api/translations/last-update/${language}/${
      apiConfig.translation_service.published ? 'published' : 'unpublished'
    }`;
  }

  cutDateString(date: string) {
    const dateString = String(date);
    return `${dateString.substr(0, 19)}Z`;
  }

  subscribeToStore(store: Store) {
    store.subscribe(() => {
      const state = store.getState();
      let currentLocale: any = getSystemLocale(state);
      if (!localStorage.jwt) {
        currentLocale = getBrowserLocale();
      }
      if (currentLocale && currentLocale !== this.lastLocale && isSystemLocaleAllowed(state, currentLocale)) {
        i18next.changeLanguage(currentLocale.split('_')[0]);
        moment.locale(currentLocale);
        moment.updateLocale(currentLocale.split('_')[0], {
          relativeTime: momentTranslations[currentLocale.split('_')[0]],
        });
        this.lastLocale = currentLocale;
      }
    });
  }
}

const translations = new Translations();

export default translations;
