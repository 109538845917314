/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction, Dispatch } from 'redux';
import { getBookingClient } from '../../../api/api-client';
import { removeBooking, setBookings } from './bookingsSlice';
import { AxiosResponse } from 'axios';
import { isApiError } from '../../../services/utils';
import { BookingParams } from '../../../interfaces/bookings.interface';
import { NavigateFunction } from 'react-router-dom';
import { resetBookingForm } from '../booking/bookingSlice';

export const getBookings =
  (userId: string) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> =>
    getBookingClient()
      .get(`/api/bookings?filter.user=${userId}`)
      .then(response => {
        dispatch(setBookings(response.data));
      });

export const cancelBooking =
  (bookingId: string) =>
  (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> =>
    getBookingClient()
      .delete(`/api/bookings/${bookingId}`)
      .then(rsp => {
        if (!isApiError(rsp)) {
          dispatch(removeBooking(bookingId));
        }
        return rsp;
      }) as Promise<AxiosResponse>;

export const addBooking =
  (newBooking: BookingParams) =>
  (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse> => {
    return getBookingClient()
      .post('/api/bookings', newBooking)
      .then(rsp => {
        dispatch(resetBookingForm());

        return rsp
      });
  };
