import { CircularProgress, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '../../components/icons/EmailIcon';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import AccountIcon from '@mui/icons-material/AccountCircleOutlined';
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { selectSession, SessionState } from '../store/session/sessionSlice';
import { LoadingButton } from '@mui/lab';
import { signup } from '../store/session/actions';
import { AxiosError } from 'axios';
import { useNotifications } from '../../hooks/useNotifications';
import { LaravelErrors } from '../../interfaces/api.interface';
import i18next from 'i18next';
import { signupConfig } from '../../config/server.index';

export const Signup = (): React.ReactElement => {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const currentSession: SessionState = useSelector(selectSession);
  const { showLaravelErrors } = useNotifications();

  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const handleSignup = () => {
    dispatch(signup({
      companyName: company,
      firstname,
      lastname,
      email,
      phoneNumber: phone,
      utm_source: 'spaceloop'
    }))
      .then(() => {
        document.location.replace(signupConfig.redirects[i18next.language] || signupConfig.redirects['en'])
      })
      .catch((err: AxiosError) => {
        err.response && showLaravelErrors(err.response.data as LaravelErrors)
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={0}
      style={{ minHeight: '100vh' }}
    >
      <Grid
        container
        item
        xs={12}
        md={7}
        alignItems="center"
        justifyContent="flex-end"
        style={{ backgroundColor: 'white', minHeight: '100vh', display: 'flex' }}
      >
        <Grid item xs={8} justifyContent="left" style={{ paddingRight: '10vh' }}>
          <Typography variant="h2" style={{ color: 'rgb(230, 64, 111)', fontWeight: '500', marginBottom: '3rem' }}>
            {t('spaceloop.signup.title')}
          </Typography>
          <Typography style={{ color: 'rgb(78, 84, 107)', fontSize: '1.1rem', letterSpacing: '0.3px' }}>
            {t('spaceloop.signup.text')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={5}
        style={{ minHeight: '100vh', background: 'linear-gradient(to right,#2c3f73,#0c1733)' }}
        alignItems="center"
      >
        <Grid item md={7} justifyContent="left" style={{ paddingLeft: '10vh' }}>
          <Stack>
            <Typography variant="h3" style={{ color: 'white', marginBottom: '10px', textAlign: 'center' }}>
              {t('spaceloop.signup.form_title')}
            </Typography>
            <Typography style={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
              {t('spaceloop.signup.form_sub_title')}
            </Typography>
            <TextField
              label={t('spaceloop.signup.company')}
              value={company}
              onChange={e => setCompany(e.target.value)}
              name="company"
              type="text"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ArticleIcon sx={{ width: 25, height: 25 }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t('spaceloop.signup.email')}
              type="text"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={{ width: 25, height: 25 }} />
                  </InputAdornment>
                ),
              }}
            />
            <Grid flex={1}>
              <TextField
                label={t('spaceloop.signup.firstname')}
                type="text"
                name="firstname"
                value={firstname}
                onChange={e => setFirstname(e.target.value)}
                variant="outlined"
                style={{ width: '49%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountIcon sx={{ width: 25, height: 25 }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t('spaceloop.signup.lastname')}
                type="text"
                name="lastname"
                value={lastname}
                onChange={e => setLastname(e.target.value)}
                variant="outlined"
                style={{ width: '49%', marginLeft: '2%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountIcon sx={{ width: 25, height: 25 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <TextField
              label={t('spaceloop.signup.phone')}
              type="text"
              name="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon sx={{ width: 25, height: 25 }} />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              loading={currentSession.signupLoading}
              onClick={handleSignup}
              fullWidth
              variant="primary"
              loadingIndicator={<CircularProgress color="inherit" size={16} style={{ color: 'white' }} />}
            >Sign Up</LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
