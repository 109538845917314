import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Amenity } from '../../../interfaces/amenity.interface';

export interface AmenityState {
  amenities: Array<Amenity>;
  currentAmenities: Array<Amenity>;
  amenityLoading: boolean;
}

const initialState: AmenityState = {
  amenities: [],
  currentAmenities: [],
  amenityLoading: false,
};

export const amenitySlice = createSlice({
  name: 'amenity',
  initialState,
  reducers: {
    resetAmenities: () => initialState,
    amenityLoading: state => ({ ...state, amenityLoading: true }),
    amenityNotLoading: state => ({ ...state, amenityLoading: false }),
    setAmenities: (state, action: PayloadAction<Array<Amenity>>) => ({
      ...state, amenities: action.payload,
    }),
    setCurrentAmenities: (state: AmenityState, action: PayloadAction<Array<Amenity>>) => (
      { ...state, currentAmenities: action.payload }
    ),
  },
});

export const { amenityLoading, amenityNotLoading, setAmenities, resetAmenities } = amenitySlice.actions;

export const selectAmenity = (state: RootState) => state.amenity;

export default amenitySlice.reducer;
