import { Company } from '../../interfaces/company.interface';
import { SessionUser } from '../../interfaces/session.types';
import { getUserClient } from '../api-client';

export const getCompany = (token?: string): Promise<Company> => {
  return new Promise((resolve, reject) => {
    const config =
      token === undefined
        ? {}
        : {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    getUserClient()
      .get('/company?setting_attributes=1&permissions=1', {
        ...config,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getUserPermissions = (): Promise<SessionUser> => {
  return new Promise((resolve, reject) => {
    getUserClient()
      .get('/me?setting_attributes=1&permissions=1&manager_attributes=1&extended=1')
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
