import { setup } from './server.config';

const defaultRequest = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const apiConfig = {
  api: {
    base_url: setup.API_URL,
    defaultRequest,
    apiVerStoreKey: 'apiVersion',
  },
  logService: {
    base_url: setup.LOG_SERVICE_URL,
    defaultRequest,
  },
  debug: setup.DEBUG,
  log_level: setup.LOG_LEVEL,
  translation_service: {
    url: setup.TRANSLATION_SERVICE_URL,
    published: setup.PUBLISHED_TRANSLATIONS,
  },
};

export const signupConfig: {redirects: Record<string, string>} = {
  redirects: {
    de: setup.SIGNUP_REDIRECT_DE,
    en: setup.SIGNUP_REDIRECT_EN,
  },
}
