import { configureStore } from '@reduxjs/toolkit';
import sessionReducer, { resetSession } from './session/sessionSlice';
import attributesReducer, { resetAttributes } from './attribute/attributeSlice';
import officeReducer, { resetOffice } from './office/officeSlice';
import floorReducer from './floor/floorSlice';
import bookingReducer, { resetBookingForm } from './booking/bookingSlice';
import amenityReducer, { resetAmenities } from './amenity/amenitySlice';
import bookableReducer, { resetBookables } from './bookable/bookableSlice';
import bookingsReducer, { resetBookings } from './bookings/bookingsSlice';
import { AnyAction, Dispatch } from 'redux';
import { storeToken } from './session/cookie';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    attribute: attributesReducer,
    office: officeReducer,
    booking: bookingReducer,
    floor: floorReducer,
    amenity: amenityReducer,
    bookable: bookableReducer,
    bookings: bookingsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  storeToken('');
  dispatch(resetSession());
  dispatch(resetBookingForm());
  dispatch(resetOffice());
  dispatch(resetBookings());
  dispatch(resetBookables());
  dispatch(resetAttributes());
  dispatch(resetAmenities());
};
