import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Home = (): React.ReactElement => {
  const [t] = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', background: 'rgb(237,237,237)' }}
      display="flex"
    >
      <Grid>
        <Grid paddingBottom={8} textAlign={'center'}>
          <Typography
            variant="h3"
            color={'rgba(78,84,107,1)'}
            style={{ fontSize: '3rem', fontWeight: 500 }}
          >
            {t('spaceloop.home.title')}
          </Typography>
          <br />
          <Typography
            variant="h3"
            color={'rgba(78,84,107,1)'}
            style={{ fontSize: '2rem', fontWeight: 300 }}
            dangerouslySetInnerHTML={{__html: t('spaceloop.home.home_hint')}}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
