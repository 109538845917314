export default {
  palette: {
    primary: {
      main: '#4E546B',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(),
    body1: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 15,
    },
    h6: {
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: 15,
      fontWeight: 600,
      color: 'rgba(78,84,107,1)', // TODO: Import from "custom theme palette"
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '20px',
          marginBottom: '20px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'rgba(78,84,107,0.6)', // TODO: Import from "custom theme palette"
          transition: 'color 0.3s',
          '&:hover': {
            color: 'rgba(78,84,107,1)', // TODO: Import from "custom theme palette"
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'landing' },
          style: {
            borderRadius: 50,
            lineHeight: '25px',
            color: '#fff',
            background: '#ff4c7a', // TODO: Import from "custom theme palette"
            width: '100%',
            minWidth: 'auto',
            margin: 0,
            border: 'none',
            fontSize: '1rem',
            textAlign: 'center',
            alignSelf: 'start',
            textTransform: 'uppercase',
            display: 'pointer',
            position: 'relative',
            fontWeight: '200',
            height: '50px',
            padding: '0 30px',
            transition: 'background 0.5s ease-out',
            '&:hover': {
              color: '#fff',
              background: '#ff678d', // TODO: Import from "custom theme palette"
              width: '100%',
              minWidth: 'auto',
              margin: 0,
              border: 'none',
              fontSize: '1rem',
              textAlign: 'center',
              alignSelf: 'start',
              display: 'pointer',
              borderRadius: '50x',
              position: 'relative',
            },
          },
        },
        {
          props: { variant: 'basic' },
          style: {
            justifySelf: 'center',
            width: '100%',
            border: '1px solid rgba(255,255,255,1)',
            borderRadius: 5,
            backgroundColor: '#ffffff',
            marginBottom: '15px',
            padding: '30px',
            boxShadow: '0 4px 14px 5px rgb(78 84 107 / 5%)',
            lineHeight: 'normal',
            color: 'rgba(78,84,107,1)', // TODO: Import from "custom theme palette"
            fontSize: '1.66em',
            textTransform: 'none',
            '&:hover': {
              justifySelf: 'center',
              width: '100%',
              border: '1px solid rgba(255,255,255,1)',
              borderRadius: 5,
              backgroundColor: '#ffffff',
              marginBottom: '15px',
              padding: '30px',
              boxShadow: '0 4px 14px 5px rgb(78 84 107 / 5%)',
              lineHeight: 'normal',
              color: 'rgba(78,84,107,1)', // TODO: Import from "custom theme palette"
              fontSize: '1.66em',
              textTransform: 'none',
              display: 'pointer',
              opacity: 0.8,
            },
            '&:disabled': {
              justifySelf: 'center',
              width: '100%',
              border: '1px solid rgba(255,255,255,0.5)',
              borderRadius: 5,
              backgroundColor: '#ffffff',
              marginBottom: '15px',
              padding: '30px',
              boxShadow: '0 4px 14px 5px rgb(78 84 107 / 5%)',
              lineHeight: 'normal',
              color: 'rgba(78,84,107,1)', // TODO: Import from "custom theme palette"
              fontSize: '1.66em',
              textTransform: 'none',
              display: 'pointer',
              opacity: 0.4,
            },
            '&::before': {
              boxSizing: 'inherit',
            },
            '&:hover:not(:disabled)': {
              '&::before': {
                opacity: 0.15,
              },
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            justifySelf: 'center',
            width: '100%',
            border: '1px solid rgb(78, 84, 107)',
            borderRadius: 5,
            backgroundColor: 'rgb(78, 84, 107)',
            marginBottom: '15px',
            padding: '30px',
            boxShadow: '0 4px 14px 5px rgb(78 84 107 / 5%)',
            lineHeight: 'normal',
            color: '#ffffff', // TODO: Import from "custom theme palette"
            fontSize: '1.66em',
            textTransform: 'none',
            '&:hover': {
              border: '1px solid rgb(78, 84, 107)',
              backgroundColor: 'rgb(78, 84, 107)',
              justifySelf: 'center',
              width: '100%',
              borderRadius: 5,
              marginBottom: '15px',
              padding: '30px',
              boxShadow: '0 4px 14px 5px rgb(78 84 107 / 5%)',
              lineHeight: 'normal',
              color: '#ffffff', // TODO: Import from "custom theme palette"
              fontSize: '1.66em',
              textTransform: 'none',
              display: 'pointer',
              opacity: 0.8,
            },
            '&::before': {
              boxSizing: 'inherit',
            },
            '&:hover:not(:disabled)': {
              '&::before': {
                opacity: 0.15,
              },
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            borderRadius: 50,
            lineHeight: '25px',
            color: '#fff',
            background: '#4E546B', // TODO: Import from "custom theme palette"
            minWidth: 'auto',
            margin: 0,
            border: 'none',
            fontSize: '1rem',
            textAlign: 'center',
            alignSelf: 'start',
            textTransform: 'none',
            position: 'relative',
            fontWeight: '200',
            height: '50px',
            padding: '0 30px',
            transition: 'background 0.5s ease-out',
            flexDirection: 'row',
            display: 'flex',
            '&:hover': {
              color: '#fff',
              background: '#4E546B', // TODO: Import from "custom theme palette"
              minWidth: 'auto',
              margin: 0,
              border: 'none',
              fontSize: '1rem',
              textAlign: 'center',
              alignSelf: 'start',
              borderRadius: '50x',
              position: 'relative',
              flexDirection: 'row',
              display: 'flex',
            },
            '&:disabled': {
              color: '#bbb',
              background: '#4E546B', // TODO: Import from "custom theme palette"
              minWidth: 'auto',
              margin: 0,
              border: 'none',
              fontSize: '1rem',
              textAlign: 'center',
              alignSelf: 'start',
              borderRadius: '50x',
              position: 'relative',
              flexDirection: 'row',
              display: 'flex',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            borderRadius: 50,
            lineHeight: '25px',
            color: '#4E546B',
            background: '#fff', // TODO: Import from "custom theme palette"
            minWidth: 'auto',
            margin: 0,
            border: '1px solid #4E546B',
            fontSize: '1rem',
            textAlign: 'center',
            alignSelf: 'start',
            textTransform: 'none',
            position: 'relative',
            fontWeight: '200',
            height: '50px',
            padding: '0 30px',
            transition: 'background 0.5s ease-out',
            flexDirection: 'row',
            display: 'flex',
            '&:hover': {
              color: '#4E546B',
              background: '#fff', // TODO: Import from "custom theme palette"
              minWidth: 'auto',
              margin: 0,
              border: '1px solid #4E546B',
              backgroundColor: '#dddddd',
              fontSize: '1rem',
              textAlign: 'center',
              alignSelf: 'start',
              borderRadius: '50x',
              position: 'relative',
              flexDirection: 'row',
              display: 'flex',
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '60px',
          margin: '0 10px 15px 0',
          width: '100%',
          color: 'rgba(78,84,107,1)',
          backgroundColor: 'rgba(255,255,255,1)',
          borderRadius: 5,
          border: 'solid 1px rgba(0,0,0,0.1)',
          boxShadow: '0 0 14px 0 rgb(78 84 107 / 5%)',
          transition: 'all 0.2s ease-in',
          fontWeight: 400,
          fontSize: '15px',
          '&.Mui-focused': {
            boxShadow: '0 18px 30px -11px rgb(78 84 107 / 20%)',
            border: 'solid 1px rgba(78,84,107,1)',
            outline: 'none',
            '& .MuiSvgIcon-root': {
              opacity: 1,
            },
          },
          '& .Mui-focused': {
            border: 'solid 1px #4e546b99',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
          },
          '& .MuiSvgIcon-root': {
            opacity: 0.5,
          },
        },
        input: {
          fontSize: '0.867rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          color: 'rgba(78,84,107,0.7)',
          transition: '0.2s ease all',
          textTransform: 'uppercase',
          transformOrigin: 'unset',
          transform: 'none',
          left: 15,
          top: 21,
          '&.Mui-focused': {
            fontSize: '0.7rem',
            color: 'rgba(78,84,107)',
            transform: 'translateY(-11px)',
          },
          '&.MuiFormLabel-filled': {
            fontSize: '0.7rem',
            color: 'rgba(78,84,107)',
            transform: 'translateY(-11px)',
          },
        },
        asterisk: {
          color: '#f00',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
        input: {
          padding: '22px 14px 6px 7px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '22px 14px 6px 7px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: any) => {
          // Adjust label position if start adornment is present
          if (ownerState.InputProps?.startAdornment) {
            return {
              '& .MuiInputLabel-root': {
                left: 55,
              },
            };
          } else {
            return {
              '& .MuiOutlinedInput-input': {
                paddingLeft: '14px',
              },
            };
          }
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: '14px',
          textAlign: 'left',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#4E546B',
            '& + .MuiSwitch-track': {
              background: '#4E546B',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '20px',
          lineHeight: '2rem',
          '&.Mui-selected': {
            backgroundColor: 'white',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
          '&:active': {
            background: 'white',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
          '&:hover': {
            transition: '0.3s',
            background: 'white',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
        },
      },
    },
  },
};
