import React, { FC, PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { LoadingScreen } from './LoadingScreen';

interface Props {
  loading?: boolean;
}

export const Page: FC<PropsWithChildren<Props>> = ({children, loading}) => (
  <Grid
    container
    spacing={0}
    justifyContent="center"
    style={{ minHeight: '100vh', background: 'rgb(237,237,237)', paddingTop: '3rem' }}
  >{(loading && <LoadingScreen />) || children || <></>}</Grid>
)
