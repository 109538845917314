/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { selectOffice } from '../../store/office/officeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { translateContent } from '../../../services/translationServices';
import { Office } from '../../../interfaces/office.interface';
import { selectBookingOffice, selectBookingType } from '../../store/booking/actions';
import { BookableTypes } from '../../../interfaces/bookable.interface';
import { useTranslation } from 'react-i18next';
import { selectBooking } from '../../store/booking/bookingSlice';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/loadable/Page';

export const OfficeSelect = (): ReactElement => {
  const currentOffices: Array<Office> = useSelector(selectOffice).offices;
  const currentBooking = useSelector(selectBooking);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const setOffice = (event: SelectChangeEvent) => {
    const officeId = event.target.value as string;
    if (officeId === '') {
      return;
    }
    dispatch(selectBookingOffice(currentOffices.find(office => office.id === officeId) as Office));
  };
  const setBookableType = (type: string) => {
    dispatch(selectBookingType(type));
    navigate('/booking/floor');
  };

  return <Page>
    <Grid item xs={11} md={8}>
      <Typography
        variant="h3"
        color={'rgba(78,84,107,1)'}
        style={{ fontSize: '3rem', fontWeight: 500 }}
      >
        {'Booking'}
      </Typography>
      <Divider />
      <FormControl fullWidth>
        <InputLabel id="select-booking-office">{t('spaceloop.booking.select_office')}</InputLabel>
        <Select onChange={setOffice} value={currentBooking.office?.id || ''} labelId="select-booking-office">
          {currentOffices.map(
            (office, i) =>
              <MenuItem value={office.id} key={i}>{translateContent(office.name)}</MenuItem>,
          )}
        </Select>
        {
          BookableTypes.map(type => <Button
            disabled={!currentBooking.office}
            key={type.slug}
            variant={currentBooking.bookableType === type.slug ? 'contained' : 'basic'}
            onClick={() => setBookableType(type.slug)}
          >
            {t('booking.bookable_type_' + type.slug)}
          </Button>)
        }
      </FormControl>
    </Grid>
  </Page>;
};
