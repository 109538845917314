import React, { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { useNotifications } from '../hooks/useNotifications';
import { selectSession, SessionState } from './store/session/sessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Office } from './pages/offices/Office';
import { OfficeSelect } from './pages/booking/OfficeSelect';
import { FloorSelect } from './pages/booking/FloorSelect';
import { DeletedOffice } from './pages/DeletedOffice';
import { Bookings } from './pages/Bookings';
import { ProtectedRoute } from '../components/routes/ProtectedRoute';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { ForgotPassword } from './pages/ForgotPassword';
import { logout } from './store';
import { UserPermissions } from '../interfaces/session.types';
import { PublicRoute } from '../components/routes/PublicRoute';
import { Signup } from './pages/Signup';

export const Routing = (): ReactElement => {
  const dispatch = useDispatch<any>();
  const currentSession: SessionState = useSelector(selectSession);
  const { showError } = useNotifications();

  useEffect(() => {
    if (!!currentSession.company && !currentSession.company?.features.booking) {
      showError('booking.not_enabled');
      dispatch(logout());
    }
  }, [currentSession.company]);

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/offices/:id/floors/:floorId"
             element={<ProtectedRoute permission={UserPermissions.ManageCompany}><Office /></ProtectedRoute>} />
      <Route path="/offices/:id"
             element={<ProtectedRoute permission={UserPermissions.ManageCompany}><Office /></ProtectedRoute>} />
      <Route path="/deleted-office"
             element={<ProtectedRoute permission={UserPermissions.ManageCompany}><DeletedOffice /></ProtectedRoute>} />
      <Route path="/bookings/:user" element={<ProtectedRoute><Bookings /></ProtectedRoute>} />
      <Route path="/booking" element={<Navigate to="/booking/init" />} />
      <Route path="/booking/init" element={<ProtectedRoute><OfficeSelect /></ProtectedRoute>} />
      <Route path="/booking/floor" element={<ProtectedRoute><FloorSelect /></ProtectedRoute>} />
      <Route path="/bookings/:user" element={<ProtectedRoute><Bookings /></ProtectedRoute>} />
      <Route path="/forgot-password" element={<PublicRoute publicOnly><ForgotPassword /></PublicRoute>} />
      <Route path="/login" element={<PublicRoute publicOnly><Login /></PublicRoute>} />
      <Route path="/signup" element={<PublicRoute publicOnly><Signup /></PublicRoute>} />
      <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
    </Routes>
  );
};
