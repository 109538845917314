import axios from 'axios';
import { store } from '../app/store';
import { setup } from '../config/server.config';

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

export const getUserClient = () => {
  const options = {
    baseURL: setup.API_URL,
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => {
      const storeState = store.getState();
      const token = storeState.session.token as string;

      if (requestConfig.headers && !!token) {
        requestConfig.headers['Authorization'] = `Bearer ${token}`;
      }
      return requestConfig;
    },

    requestError => Promise.reject(requestError),
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
  );

  return client;
};

export const getBookingClient = () => {
  const options = {
    baseURL: setup.BOOKING_API_URL,
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => {
      const storeState = store.getState();
      const token = storeState.session.token as string;
      if (requestConfig.headers && !!token) {
        requestConfig.headers['Authorization'] = `Bearer ${token}`;
      }
      return requestConfig;
    },
    requestError => Promise.reject(requestError),
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
  );

  return client;
};
