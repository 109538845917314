import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Bookable } from '../../../interfaces/bookable.interface';

export interface BookableMarker {
  itemNumber?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface BookableState {
  currentBookableMarker: BookableMarker;
  selectedBookable: Bookable | null;
  bookables: Array<Bookable>;
  bookableLoading: boolean;
}

const initialState: BookableState = {
  currentBookableMarker: {},
  selectedBookable: null,
  bookables: [],
  bookableLoading: true,
};

export const bookableSlice = createSlice({
  name: 'bookable',
  initialState,
  reducers: {
    resetBookables: () => initialState,
    bookableLoading: state => {
      state.bookableLoading = true;
    },
    bookableNotLoading: state => {
      state.bookableLoading = false;
    },
    setBookables: (state, action: PayloadAction<Array<Bookable>>) => {
      state.bookables = action.payload;
    },
    setCurrentBookableMarker: (state, action) => {
      state.currentBookableMarker = action.payload;
    },
    setSelectedBookable: (state, action) => {
      state.selectedBookable = action.payload;
    },
    resetContext(state) {
      state.currentBookableMarker = {};
      state.selectedBookable = null;
    },
  },
});

export const {
  bookableLoading,
  bookableNotLoading,
  setBookables,
  setCurrentBookableMarker,
  setSelectedBookable,
  resetContext,
  resetBookables,
} = bookableSlice.actions;

export const selectBookable = (state: RootState) => state.bookable;

export default bookableSlice.reducer;
