import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
export default function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g fill="none" fillRule="evenodd" stroke="currentColor">
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M4.679 5.5L4 6.24l8.5 9.26L21 6.24l-.679-.74-7.821 8.52z"
          />
          <rect width="21" height="16" x="2" y="5" strokeWidth="2" rx="5" />
        </g>
      </svg>
    </SvgIcon>
  );
}
