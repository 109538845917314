import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Booking } from '../../../interfaces/bookings.interface';
import { RootState } from '../index';

export interface BookingsState {
  bookings: Booking[];
}

const initialState: BookingsState = {
  bookings: [],
}

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState: initialState,
  reducers: {
    resetBookings: () => initialState,
    setBookings: (state, action: PayloadAction<Booking[]>) => {
      state.bookings = action.payload;
    },
    removeBooking: (state, action: PayloadAction<string>) => {
      state.bookings = state.bookings.filter(b => b.id !== action.payload)
    }
  },
});
export const selectBookings = (state: RootState) => state.bookings;

export const { setBookings, removeBooking, resetBookings } =
  bookingsSlice.actions;

export default bookingsSlice.reducer;
