import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { subscribeContentLanguageToStore } from './services/translationServices';
import translations from './services/translationInit';
import {
  getAvailableSystemLanguages,
  getBrowserLanguage,
  getDefaultSystemLanguage,
  getSystemLanguage,
} from './services/session';
import { setI18n } from 'react-i18next';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'

function startApp() {
  dayjs.extend(localizedFormat);

  dayjs().format('L LT');
  let initialSystemLanguage = getSystemLanguage(store.getState());
  if (typeof initialSystemLanguage === 'string') {
    if (!localStorage.getItem('jwt')) {
      initialSystemLanguage = getBrowserLanguage();
    }
    const defaultSystemLanguage = getDefaultSystemLanguage(store.getState());
    const initialAvailableLanguage = getAvailableSystemLanguages(store.getState());

    subscribeContentLanguageToStore(store);
    translations
      .init(initialAvailableLanguage, initialSystemLanguage, defaultSystemLanguage, store)
      .then(i18n => {
        setI18n(i18n);
        const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
        root.render(
          <Provider store={store}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </Provider>,
        );
      });
  }
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
