import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
export default function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g
          fill="none"
          fillRule="nonzero"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M5 13h10M7 9l-4 4.002L7 17M13 4h8v18h-8" />
        </g>
      </svg>
    </SvgIcon>
  );
}
