import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Attribute } from '../../../interfaces/attributes.interface';
import { mapKeyToData } from '../../../services/utils';

export interface AttributesState {
  loading: boolean;
  error: Record<string, any>;
  byId: Record<string, Attribute>;
}

const initialState = {
  loading: false,
  error: {},
  byId: {},
}

export const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    resetAttributes: () => initialState,
    getAllAttributesRequest: state => {
      state.loading = true;
    },
    getAllAttributesSuccess: (state, action: PayloadAction<Array<Record<string, Attribute>>>) => {
      state.loading = false;
      state.byId = mapKeyToData(action.payload, 'uuid');
    },
    getAllAttributesFailed: (state, action: PayloadAction<Record<string, any>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllAttributesRequest, getAllAttributesSuccess, getAllAttributesFailed, resetAttributes } =
  attributeSlice.actions;

export default attributeSlice.reducer;
