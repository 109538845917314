import { Segment } from './segment.interface';

export enum AttrInternalType {
  Manager = 'manager',
  Setting = 'setting',
  Option = 'option',
  Decimal = 'decimal',
  Datetime = 'datetime',
}

export enum AttrSlugType {
  AnonymityMinAnswers = 'lls_system_anonymity_min_answers',
  SystemLanguage = 'lls_system_language',
  ContentLanguage = 'lls_content_language',
  Manager = 'lls_manager',
  Timezone = 'lls_timezone',
}

export interface AttributeOption {
  uuid: string;
  value: { [key: string]: string };
  position: number;
  segments?: Segment[];
  framework?: boolean;
}

export interface Attribute {
  uuid: string;
  slug: string;
  name: { [key: string]: string };
  description?: { [key: string]: string };
  created_at: string;
  updated_at: string;
  internal_type: AttrInternalType;
  attribute_options: AttributeOption[];
  hide_from_users?: boolean;
}
