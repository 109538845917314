import { Tab, Tabs } from '@mui/material';
import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { floorModalOpen, FloorState, selectFloor } from '../../store/floor/floorSlice';
import { useDispatch, useSelector } from 'react-redux';
import FloorModal from '../../../components/modals/FloorModal';
import { FloorEditForm } from './FloorEditForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getFloors, setActiveFloor } from '../../store/floor/action';
import { translateContent } from '../../../services/translationServices';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../components/loadable/Section';

interface Props {
  officeId: string;
}

export const Floors: FC<Props> = ({ officeId }) => {
  const [t] = useTranslation();

  const params = useParams();
  const currentFloors: FloorState = useSelector(selectFloor);
  const floor = currentFloors.floors.find(item => item.id === currentFloors.selectedFloorId);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const mappedFloors = [...currentFloors.floors.map(item => ({
    'id': item.id,
    'name': translateContent(item.name),
  }))];

  useEffect(() => {
    dispatch(getFloors(officeId));
  }, [officeId]);

  useEffect(() => {
    if (!floor && mappedFloors.length > 0) {
      dispatch(setActiveFloor(officeId, mappedFloors[0].id), navigate);
    }
    if (params.floorId && floor && floor.id !== params.floorId) {
      dispatch(setActiveFloor(officeId, params.floorId));
    }
  }, [currentFloors.floors, params.floorId]);

  return (
    <>
      <Tabs value={floor?.id || ''}>
        {currentFloors.floors && mappedFloors
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map((item, i) => (
              <Tab
                key={i}
                label={item.name}
                value={item.id}
                onClick={() => dispatch(setActiveFloor(officeId, item.id, navigate))}
              />
            ),
          )}
        <Tab
          label={t('spaceloop.floors.create_new_floor')}
          icon={<AddIcon />} iconPosition="end"
          onClick={() => dispatch(floorModalOpen())}
          value=""
        />
      </Tabs>
      {
        <Section loading={currentFloors.floorLoading}>
          {floor && <div role="tabpanel" style={{ marginTop: 0 }}><FloorEditForm floor={floor} /></div>}
        </Section>
      }
      <FloorModal />
    </>
  );
};
