import { Button, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import EmailIcon from '../../components/icons/EmailIcon';
import { useDispatch, useSelector } from 'react-redux';

import { selectSession, SessionState } from '../store/session/sessionSlice';
import { useNotifications } from '../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../store/session/actions';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiError } from '../../interfaces/api.interface';

export const ForgotPassword = (): React.ReactElement => {
  const { showError, showSuccess, showApiError, closeNotifications } = useNotifications();
  const [email, setEmail] = useState<string>('');
  const currentSession: SessionState = useSelector(selectSession);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (currentSession.authStatus) {
      navigate('/', { replace: true });
    }
  });

  const handleRequest = () => {
    closeNotifications();
    if (email === '' || email.search(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) === -1) {
      showError(t<string>('spaceloop.invalid_email_address'));

      return;
    }
    (dispatch(resetPassword(email)) as Promise<AxiosResponse>).then(rsp => {
      if (rsp.status >= 400) {
        showApiError(rsp.data)

        return
      }
      showSuccess(t('spaceloop.forgot_password.mail_sent') as string)
      navigate('/login')
    }).catch((err: AxiosError) => {
      showApiError((err.response?.data || []) as ApiError[])
    })
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', background: 'linear-gradient(to right,#2c3f73,#0c1733)' }}
      display="flex"
    >
      <Grid paddingBottom={8} textAlign={'center'}>
        <Typography variant="h3" color={'#fff'} style={{ fontSize: '3rem' }}>
          {t('spaceloop.forgot_password.title')}
        </Typography>
      </Grid>
      <Grid width={'359px'} alignItems="center">
        <Grid width="100%">
          <TextField
            label={t('spaceloop.login.email')}
            type="email"
            variant="outlined"
            fullWidth
            id="deskloopEmailInput"
            onChange={event => setEmail(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon sx={{ width: 25, height: 25 }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item flexDirection={'row'}>
          <Link href="/login" underline="hover" color="#fff" fontSize={'13px'} textAlign={'left'}>
            {t('x.forgot_password.nevermind')}
          </Link>
        </Grid>
        <Grid item paddingTop={5} width="100%">
          <Button variant="landing" onClick={handleRequest} color="error">
            {t('spaceloop.forgot_password.button')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
