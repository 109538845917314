import React, { FC, PropsWithChildren } from 'react';
import { LoadingScreen } from './LoadingScreen';

interface Props {
  loading?: boolean;
}

export const Section: FC<PropsWithChildren<Props>> = ({children, loading}) => {
  if (loading) {
    return <LoadingScreen />
  }
  return <>{children}</>;
}
