import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { OfficeState, selectOffice } from '../../store/office/officeSlice';
import { getOffices } from '../../store/office/actions';
import { OfficeEditForm } from './OfficeEditForm';
import { Floors } from './Floors';
import { Page } from '../../../components/loadable/Page';
import { useTranslation } from 'react-i18next';
import { translateContent } from '../../../services/translationServices';
import { sprintf } from 'sprintf-js';

export const Office = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const currentOffices: OfficeState = useSelector(selectOffice);
  const params = useParams();
  const office = currentOffices.offices.find(item => item.id === params.id);

  const fetchOffices = async () => {
    await dispatch(getOffices());
  };

  useEffect(() => {
    fetchOffices();
  }, [params.id]);

  return (
    <Page loading={!office}>
      {office && <Grid item xs={11} md={8}>
        <Stack spacing={5}>
          <Typography
            variant="h3"
            color={'rgba(78,84,107,1)'}
            style={{ fontSize: '3rem', fontWeight: 500, textAlign: 'center' }}
          >{sprintf(t('spaceloop.office.details_title'), translateContent(office.name))}</Typography>
          {!currentOffices.officeLoading &&
            <>
              <OfficeEditForm
                id={office.id}
                currentName={translateContent(office.name)}
                currentLocation={translateContent(office.location)}
              />
              <Floors officeId={office.id} />
            </>
          }
        </Stack>
      </Grid>
      }
    </Page>
  );
};
