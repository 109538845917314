import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectSession, SessionState } from '../store/session/sessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cancelBooking, getBookings } from '../store/bookings/action';
import { BookingsState, selectBookings } from '../store/bookings/bookingsSlice';
import dayjs from 'dayjs';
import { translateContent } from '../../services/translationServices';
import { useNotifications } from '../../hooks/useNotifications';
import { AxiosResponse } from 'axios';
import { isApiError } from '../../services/utils';
import { sprintf } from 'sprintf-js';
import { DeleteDialog } from '../../components/modals/DeletionDialog';
import { Page } from '../../components/loadable/Page';

export const Bookings = (): React.ReactElement => {
  const [t] = useTranslation();
  const { user: currentUserId } = useParams<{ user: string }>();
  const [showCancelModal, setShowCancelModal] = useState<string | null>(null);
  const currentBookings: BookingsState = useSelector(selectBookings);
  const currentSession: SessionState = useSelector(selectSession);
  const dispatch = useDispatch<any>();
  const { showApiError } = useNotifications();

  const handleCancelModalClose = () => {
    setShowCancelModal(null);
  };

  useEffect(() => {
    if (currentUserId) {
      dispatch(getBookings(currentUserId));
    }
  }, []);

  const cancel = (bookingId: string) => {
    (dispatch(cancelBooking(bookingId)) as Promise<AxiosResponse>).then((rsp) => {
      if (isApiError(rsp)) {
        showApiError(rsp.data);

        return;
      }
      handleCancelModalClose();
    }).catch((rsp) => {
      showApiError(rsp.response.data);
    });
  };

  return (
    <Page>
      <Grid item xs={11} md={8}>
        <Typography
          variant="h3"
          color={'rgba(78,84,107,1)'}
          style={{ fontSize: '3rem', fontWeight: 500 }}
        >
          {currentUserId === currentSession.user?.uuid ? t('spaceloop.my_bookings') : t('spaceloop.user_bookings')}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('spaceloop.bookings.bookable')}</TableCell>
                <TableCell align="right">{t('spaceloop.bookings.from')}</TableCell>
                <TableCell align="right">{t('spaceloop.bookings.until')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                currentBookings.bookings && currentBookings.bookings.map((b, i) =>
                  <Fragment key={i}>
                    <DeleteDialog
                      onClose={handleCancelModalClose}
                      onConfirm={() => {
                        cancel(b.id);
                      }}
                      title={t('spaceloop.bookings.delete_modal_title')}
                      text={sprintf(
                        t('spaceloop.bookings.delete_modal_content'),
                        translateContent(b.bookable),
                        dayjs(b.start).format('L LT'), dayjs(b.until).format('L LT'),
                      )}
                      open={b.id === showCancelModal}
                    />
                    <TableRow style={{ background: 'white' }}>
                      <TableCell>{translateContent(b.bookable)} in {translateContent(b.office)} ({translateContent(b.floor)})
                      </TableCell>
                      <TableCell align="right">{dayjs(b.start).format('L LT')}</TableCell>
                      <TableCell align="right">{dayjs(b.until).format('L LT')}</TableCell>
                      <TableCell align="right">
                        {
                          dayjs(b.until).isAfter(new Date()) &&
                          <Button variant="primary" onClick={() => {
                            setShowCancelModal(b.id);
                          }}>{t('spaceloop.button.cancel_booking')}</Button>
                        }
                      </TableCell>
                    </TableRow>
                  </Fragment>,
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Page>
  );
};
