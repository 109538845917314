import { setup } from '../../../config/server.config';

export const loadTokenIfExists = (): string | undefined => {
  const name = 'jwt=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      const v = c.substring(name.length, c.length);

      return v === 'undefined' ? undefined : v;
    }
  }
  return '';
};

export const storeToken = (token: string) => {
  document.cookie = 'jwt=' + encodeURIComponent(token) + '; secure; samesite=strict; path=/;';
  const i = document.createElement('iframe');
  i.id = 'sso_loop_now'
  i.height = '0';
  i.width = '0';
  i.src = setup.LOOP_NOW_FE + 'sso.html?token=' + token;
  i.onload = () => {
    document.body.removeChild(i)
  }
  document.body.appendChild(i);
};
