import React from 'react';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';

export interface SidebarItemProps {
  icon?: JSX.Element;
  menu?: JSX.Element;
  onClick?: () => void;
}

export default function SidebarItem({ icon, menu, onClick }: SidebarItemProps) {
  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton sx={{ minHeight: 48, justifyContent: 'center' }}>
        <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>
      </ListItemButton>
      {menu}
    </ListItem>
  );
}
