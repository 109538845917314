import { AnyAction, Dispatch } from 'redux';
import { Floor } from '../../../interfaces/floor.interface';
import { Office } from '../../../interfaces/office.interface';
import {
  bookableTypeSelected,
  floorSelected,
  fromSelected,
  officeSelected, setTimeslotValid,
  untilSelected,
} from './bookingSlice';

export const selectBookingOffice = (office: Office) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(officeSelected(office));
};

export const selectBookingFloor = (floor: Floor | null) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(floorSelected(floor));
};

export const selectBookingType = (type: string) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(bookableTypeSelected(type));
};

export const selectBookingFrom = (from: string | null) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(fromSelected(from));
};

export const selectBookingUntil = (until: string | null) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(untilSelected(until));
};

export const validateTimeslot = (valid: boolean) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setTimeslotValid(valid));
};
