import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttrSlugType } from '../../../interfaces/attributes.interface';
import { getCompanyValueBySlug } from '../../../services/attributes';
import { RootState, store } from '../../store';
import { SystemLocalesEnum } from '../../../interfaces/session.types';
import { OfficeState, selectOffice } from '../../store/office/officeSlice';
import { Floor, FloorParams } from '../../../interfaces/floor.interface';
import { deleteFloor, updateFloor } from '../../store/floor/action';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from '../../../components/modals/DeletionDialog';
import { useTranslation } from 'react-i18next';
import { translateContent } from '../../../services/translationServices';
import { Floorplan } from './Floorplan';
import { AxiosError } from 'axios';
import { useNotifications } from '../../../hooks/useNotifications';
import { ApiError } from '../../../interfaces/api.interface';

interface FloorEditFormProps {
  floor: Floor;
}

export const FloorEditForm: FC<FloorEditFormProps> = ({ floor }) => {
  const currentOffices: OfficeState = useSelector(selectOffice);
  const [t] = useTranslation();
  const { showApiError } = useNotifications();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [officeId, setOfficeId] = useState<string>('');
  const [oldName, setOldName] = useState<string>('');
  const [oldOfficeId, setOldOfficeId] = useState<string>('');

  useEffect(() => {
    // For some reason, this is necessary - even if the component is unmounted, values are kept in the local states
    setName(translateContent(floor.name));
    setOfficeId(floor.office_id);
    setOldName(translateContent(floor.name));
    setOldOfficeId(floor.office_id);
  }, [floor.id]);

  const companyLanguage: SystemLocalesEnum = getCompanyValueBySlug(
    store.getState() as RootState,
    AttrSlugType.SystemLanguage,
  );

  const isChanged = name !== oldName || officeId !== oldOfficeId;

  const dispatch = useDispatch<any>();

  const handleUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFloor: Partial<FloorParams> = {
      name: {
        [companyLanguage]: name,
      },
      office: officeId,
    };

    dispatch(updateFloor(updatedFloor, floor.id, navigate))
      .then(() => {
        setOldName(name);
        setOldOfficeId(officeId);
      })
      .catch((rsp: AxiosError) => {
        showApiError((rsp.response?.data || []) as ApiError[]);
      });
  };

  const handleDelete = () => {
    setDeleteDialogOpen(false);
    dispatch(deleteFloor(officeId, floor.id));
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleDeleteClickOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Box style={{ background: 'white', padding: '10px', borderRadius: '4px', marginBottom: '20px' }}>
        <Grid paddingY={8} textAlign={'center'} style={{ padding: '8px' }}>
          <TextField
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            id="nameInput"
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Office</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={officeId}
              onChange={event => setOfficeId(event.target.value as string)}
            >
              {currentOffices.offices.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {`${item.name[companyLanguage]}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item flexDirection="row" display="flex">
            <Grid item flexDirection="row" justifyContent="flex-start" display="flex" flex={1}>
              <IconButton aria-label="delete"
                          color="error" onClick={handleDeleteClickOpen}><DeleteIcon /></IconButton>
            </Grid>

            <Grid item flexDirection="row" justifyContent="flex-end" display="flex" flex={1}>
              <Button
                variant="primary"
                style={{ marginLeft: 5 }}
                onClick={handleUpdate}
                disabled={!isChanged}
              >
                {t('spaceloop.button.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={handleClose}
          onConfirm={handleDelete}
          title={t('spaceloop.floors.delete_modal_title')}
          text={t('spaceloop.floors.delete_modal_content')}
        />
      </Box>
      <Box style={{ background: 'white', padding: '10px', borderRadius: '4px', marginBottom: '20px' }}>
        <Grid paddingY={8} textAlign={'center'} style={{ padding: '8px' }}>
          <Floorplan floor={floor} />
        </Grid>
      </Box>
    </>
  );
};
