import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Company } from '../../../interfaces/company.interface';
import {
  ContentLocales,
  SessionUser,
  SystemLocales,
  SystemLocalesEnum,
  Timezones,
  UserPermissions,
} from '../../../interfaces/session.types';
import { loadTokenIfExists } from './cookie';

export interface SessionState {
  authStatus: boolean;
  appLoading: boolean;
  loginFailed: boolean;
  loginLoading: boolean;
  signupLoading: boolean;
  multiLoginLoading: boolean;
  signUpLoading: boolean;
  user: SessionUser | null;
  permissions: Array<UserPermissions>;
  company: Company | null;
  accounts: {
    hasMultipleAccounts: boolean;
    tokens: Array<string>;
  };
  redirect: string | null;
  timezones: Timezones;
  defaultContentLocale: string;
  contentLocales: ContentLocales;
  defaultSystemLocale: SystemLocalesEnum;
  systemLocales: SystemLocales;
  loadingRedeemToken: boolean;
  accountSuspended: boolean;
  loadingTimezones: boolean;
  loadingContentLocales: boolean;
  loadedLoacalesAndTimezones: boolean;
  token: string | null;
}

const initialState: SessionState = {
  authStatus: !!loadTokenIfExists(),
  loginLoading: false,
  signupLoading: false,
  loginFailed: false,
  appLoading: true,
  multiLoginLoading: false,
  signUpLoading: false,
  user: null,
  permissions: [],
  company: null,
  accounts: {
    hasMultipleAccounts: false,
    tokens: [],
  },
  redirect: null,
  timezones: {},
  defaultContentLocale: 'en_US',
  contentLocales: {},
  defaultSystemLocale: SystemLocalesEnum.EnUs,
  systemLocales: {
    de_DE: 'Deutsch',
    en_US: 'English',
  },
  loadingRedeemToken: false,
  accountSuspended: false,
  loadingTimezones: false,
  loadingContentLocales: false,
  loadedLoacalesAndTimezones: false,
  token: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: () => ({ ...initialState, authStatus: false }),
    setRedirect: (state, action: PayloadAction<string | null>) => ({ ...state, redirect: action.payload }),
    multipleAccounts: (state, action: PayloadAction<Array<string>>) => ({
      ...state, accounts: { hasMultipleAccounts: true, tokens: action.payload },
    }),
    clearMultipleAccounts: state => ({ ...state, accounts: { hasMultipleAccounts: true, tokens: [] } }),
    multiLoginLoading: state => ({ ...state, multiLoginLoading: true, loginFailed: false }),
    multiLoginNotLoading: state => ({ ...state, multiLoginLoading: false }),
    loginLoading: state => ({ ...state, loginLoading: true, loginFailed: false }),
    loginNotLoading: state => ({ ...state, loginLoading: false }),
    signupLoading: state => ({ ...state, signupLoading: true }),
    signupNotLoading: state => ({ ...state, signupLoading: false }),
    loginSuccess: (state, action: PayloadAction<string | null>) => ({
      ...state, authStatus: true, accountSuspended: false, token: action.payload, loginFailed: false,
    }),
    loginFailed: state => ({ ...state, authStatus: false, loginFailed: true }),
    accountSuspended: state => ({ ...state, accountSuspended: true }),
    setCurrentCompany: (state, action: PayloadAction<Company | null>) => ({
      ...state, company: action.payload,
    }),
    setSessionUser: (state, action: PayloadAction<SessionUser | null>) => ({
      ...state, user: action.payload,
    }),
    setUserPermissions: (state, action: PayloadAction<Array<UserPermissions>>) => ({
      ...state, permissions: action.payload,
    }),
    setAppLoaded: state => ({ ...state, appLoading: false }),
  },
});

export const {
  resetSession,
  multipleAccounts,
  clearMultipleAccounts,
  loginLoading,
  loginNotLoading,
  loginSuccess,
  loginFailed,
  signupLoading,
  signupNotLoading,
  setCurrentCompany,
  setSessionUser,
  setUserPermissions,
  setAppLoaded,
} = sessionSlice.actions;

export const selectSession = (state: RootState) => state.session;

export default sessionSlice.reducer;
