import React, { useEffect, useRef } from 'react';
import { Box, Link, Stack, StackProps, Typography } from '@mui/material';
import { SidebarDivider } from './sidebar.sc';

export interface SidebarMenuProps {
  title: string;
  titleHref?: string;
  menuItems: SidebarMenuItem[];
}

export interface SidebarMenuItem {
  text: string;
  onClick?: () => void;
  props?: StackProps;
  ref?: string;
}

export function SidebarMenu({ title, titleHref, menuItems }: SidebarMenuProps) {
  const ref = useRef<HTMLElement>();

  const handleMouseEnter = () => {
    if (ref.current) {
      ref.current.style.width = '340px';
      ref.current.style.overflow = 'auto';
      ref.current.style.opacity = '1';
    }
  };

  const handleMouseLeave = () => {
    if (ref.current) {
      ref.current.style.width = '0px';
      ref.current.style.overflow = 'hidden';
      ref.current.style.opacity = '0';
    }
  };

  useEffect(() => {
    if (!ref.current) return;

    const parentEl = ref.current.parentElement;
    parentEl?.addEventListener('mouseenter', handleMouseEnter);
    parentEl?.addEventListener('mouseleave', handleMouseLeave);
  }, [ref]);

  return (
    <Stack
      ref={ref}
      position="absolute"
      width={0}
      overflow="hidden"
      boxShadow={4}
      sx={{ backgroundColor: 'background.paper', opacity: 0, transition: 'opacity .5s' }}
      left="100%"
      top="0px"
    >
      {title && (
        <>
          <Box p={1.75}>
            <Link underline="none" href={titleHref || '#'}>
              <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
                {title}
              </Typography>
            </Link>
          </Box>
          <SidebarDivider />
        </>
      )}
      {menuItems.map((menuItem: SidebarMenuItem, index: number) => (
        <React.Fragment key={index}>
          <Stack
            key={index}
            direction="row"
            flex={1}
            justifyContent="space-between"
            px={1.75}
            py={1.25}
            onClick={() => {menuItem.onClick && menuItem.onClick(); handleMouseLeave();}}
            {...menuItem.props}
            style={{ cursor: 'pointer' }}
          >
            <Link href={menuItem.ref} underline="none" width={'100%'} key={index}>
              <Typography variant="body1">{menuItem.text}</Typography>
            </Link>
          </Stack>
          {index == 0 && <SidebarDivider />}
        </React.Fragment>
      ))}
    </Stack>
  );
}
