import React, { ReactElement } from 'react';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ApiError, LaravelErrors } from '../interfaces/api.interface';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import _ from 'lodash/fp';

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const laravelErrorToApiError = (errors: LaravelErrors): ApiError[] => {
    const out: Array<Array<ApiError>> = Object.keys(errors).map(k => {
      return errors[k].map((e): ApiError => {
        switch (e) {
          case 'Please fill this field.':
            return {
              translation_key: 'error.required_field_missing',
              message: 'Entity not found',
              arguments: {'field': k}
            }
          case 'validation.email_with_special_chars':
            return {
              translation_key: 'error.invalid_value',
              message: 'Invalid value',
              arguments: {'field': k}
            }
          default:
            return {
              translation_key: 'spaceloop.error.field_error',
              message: `Error in field ${k}`,
              arguments: {'field': k}
            }
        }
      })
    });

    return _.flatten(out)
  }

  const apiErrorToString = (error: ApiError): string => {
    switch (true) {
      case error.translation_key === 'route_not_found':
        return sprintf(t('spaceloop.error.route_not_found'), error.arguments['path']);
      case error.translation_key === 'error.value_not_in_enum':
        return sprintf(
          t('spaceloop.error.value_not_in_enum'),
          error.arguments['field'],
          error.arguments['enum'].join(', '),
          error.arguments['value'],
        );
      case error.translation_key === 'error.required_field_missing':
        return sprintf(t('spaceloop.error.required_field_missing'), error.arguments['field']);
      case error.translation_key === 'error.invalid_value':
        return sprintf(t('spaceloop.error.invalid_value'), error.arguments['field']);
      default:
        return t(`spaceloop.${error.translation_key}`);
    }
  };

  const closeAction = (snackbarId: SnackbarKey): ReactElement => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <ClearIcon sx={{ width: 15, height: 15 }} htmlColor="#fff" />
      </IconButton>
    </>
  );

  const assembleMsg = (msg: string | Array<string>): ReactElement => {
    if (typeof msg === 'string') {
      return <span>{msg}</span>;
    }
    if (msg.length === 0) {
      return <p>{msg[0]}</p>;
    }

    return (
      <ul style={{ listStyle: 'none' }}>
        {msg.map((m, i) => (
          <li key={i}>{m}</li>
        ))}
      </ul>
    );
  };

  const doEnqueue = (
    msg: string | Array<string>,
    type: VariantType | undefined,
    persist: boolean,
    duration?: number
  ): SnackbarKey =>
    enqueueSnackbar(assembleMsg(msg), {
      autoHideDuration: duration ? duration : 5000,
      variant: type,
      persist: persist,
      action: closeAction,
    })

  return {
    showNotice: (msg: string | string[], duration?: number, persist = false) =>
      doEnqueue(msg, 'info', persist, duration),
    showSuccess: (msg: string | string[], duration?: number, persist = false) =>
      doEnqueue(msg, 'success', persist, duration),
    showError: (msg: string | string[], duration?: number, persist = false) =>
      doEnqueue(msg, 'error', persist, duration),
    showApiError: (msg: ApiError | ApiError[], duration?: number, persist = false) =>
      doEnqueue(
        Array.isArray(msg) ? msg.map(apiErrorToString) : apiErrorToString(msg),
        'error',
        persist,
        duration
      ),
    showLaravelErrors: (msg: LaravelErrors, duration?: number, persist = false) =>
      doEnqueue(
        laravelErrorToApiError(msg).map(apiErrorToString),
        'error',
        persist,
        duration
      ),
    closeNotifications: () => {
      closeSnackbar();
    },
  };
};
