import React, { FC } from 'react';
import { CircularProgress, Grid } from '@mui/material';

export const LoadingScreen: FC = () => <Grid
  container
  spacing={0}
  justifyContent="center"
  alignItems="center"
  style={{ }}
><CircularProgress /></Grid>;
