import React, { useRef, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { officeModalClose, OfficeState, selectOffice } from '../../app/store/office/officeSlice';

import { selectSession, SessionState } from '../../app/store/session/sessionSlice';
import { OfficeParams } from '../../interfaces/office.interface';
import { RootState, store } from '../../app/store';
import { getCompanyValueBySlug } from '../../services/attributes';
import { AttrSlugType } from '../../interfaces/attributes.interface';
import { addOffice } from '../../app/store/office/actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OfficeModal(): React.ReactElement {
  const currentOffice: OfficeState = useSelector(selectOffice);
  const currentSession: SessionState = useSelector(selectSession);
  const dispatch = useDispatch<any>();
  const rootRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const navigate = useNavigate();
  const [t] = useTranslation();

  const handleCreateOffice = () => {
    if (!currentSession.token) return;
    const timezone = getCompanyValueBySlug(store.getState() as RootState, AttrSlugType.Timezone);
    const companyLanguage = getCompanyValueBySlug(store.getState() as RootState, AttrSlugType.SystemLanguage);

    const newOffice: OfficeParams = {
      name: {
        [companyLanguage]: name,
      },
      location: {
        [companyLanguage]: location,
      },
      timezone,
    };
    dispatch(addOffice(newOffice, navigate));
    dispatch(officeModalClose());
  };

  return (
    <Modal
      onClose={() => dispatch(officeModalClose())}
      disableEnforceFocus
      disableAutoFocus
      open={currentOffice.officeModalOpen}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      container={() => rootRef.current}
    >
      <Box
        sx={{
          position: 'relative',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #fff',
          borderRadius: 2,
          boxShadow: theme => theme.shadows[5],
          p: 4,
        }}
      >
        <Typography id="server-modal-title" variant="h4" component="h2" paddingBottom={2}>
          {t('spaceloop.sidebar.create_new_office')}
        </Typography>
        <TextField
          label={t('spaceloop.office.name')}
          type="name"
          variant="outlined"
          fullWidth
          id="nameInput"
          onChange={event => setName(event.target.value)}
        />
        <TextField
          label={t('spaceloop.office.location')}
          type="location"
          variant="outlined"
          fullWidth
          id="locationInput"
          onChange={event => setLocation(event.target.value)}
        />
        <Grid item flexDirection="row" justifyContent="flex-end" display="flex">
          <Button
            variant="primary"
            onClick={() => {
              handleCreateOffice();
            }}
            color="error"
          >
            Create
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
