import { AnyAction, Dispatch } from 'redux';
import { getUserClient } from '../../../api/api-client';
import { getCompany, getUserPermissions } from '../../../api/session/session.api';
import { Company } from '../../../interfaces/company.interface';
import { LoginUserCredentials, SessionUser, SignupParams } from '../../../interfaces/session.types';
import { fetchAttributesList } from '../attribute/actions';
import { storeToken } from './cookie';

import {
  clearMultipleAccounts,
  loginFailed,
  loginLoading,
  loginNotLoading,
  loginSuccess,
  multipleAccounts, setAppLoaded,
  setCurrentCompany,
  setSessionUser,
  setUserPermissions, signupLoading, signupNotLoading,
} from './sessionSlice';
import { AxiosResponse } from 'axios';
import { getOffices } from '../office/actions';
import { getBrowserLocale, getBrowserTimezone } from '../../../services/session';

export const getUserDetails = () => async (dispatch: Dispatch) => {
  const companyResponse = await getCompany().then((data: Company) => {
    dispatch(setCurrentCompany(data));
    return data;
  });

  const sessionUserResp = await getUserPermissions()
    .then((data: SessionUser) => {
      dispatch(setSessionUser(data));
      dispatch(setUserPermissions(data.userPermissions));
      return data;
    })
    .catch(error => {
      throw error;
    });

  return { companyResponse, sessionUserResp };
};

export const loginUser =
  (credentials: LoginUserCredentials) =>
    (dispatch: Dispatch): Promise<void> => {
      dispatch(loginLoading());
      return getUserClient()
        .post('/authenticate', credentials)
        .then(response => {
          if (response.status === 400) {
            dispatch(loginNotLoading());
            dispatch(loginFailed());
            return;
          }
          if (response.data.length > 1) {
            dispatch(multipleAccounts(response.data));
          } else {
            dispatch(initUserLogin(response.data[0]) as any as AnyAction);
          }
        })
        .catch(() => {
          dispatch(loginNotLoading());
          dispatch(loginFailed());
        });
    };

export const resetPassword = (email: string) => (dispatch: Dispatch): Promise<AxiosResponse> => {
  dispatch(loginLoading());
  return getUserClient()
    .post('/password/reset', { email, section: 'spaceloop' })
    .finally(() => {
      dispatch(loginNotLoading());
    });
};

export const initUserLogin = (token: string) => (dispatch: Dispatch) => {
  dispatch(loginLoading());
  storeToken(token);
  dispatch(loginSuccess(token));
  const fetchAttributesListPromise = dispatch(fetchAttributesList() as any as AnyAction);
  const getUserDetailsPromise = dispatch(getUserDetails() as any as AnyAction);
  dispatch(getOffices() as any as AnyAction)
  dispatch(clearMultipleAccounts());

  return Promise.all([fetchAttributesListPromise, getUserDetailsPromise]).finally(() =>
    dispatch(loginNotLoading()),
  );
};

export const appIsLoaded = () => (dispatch: Dispatch<any>) => {
  dispatch(setAppLoaded())
}

export const signup = (params: SignupParams) => (dispatch: Dispatch): Promise<AxiosResponse> => {
  dispatch(signupLoading())
  const postData = {
    ...params,
    okrs: false,
    surveys: false,
    booking: true,
    timezone: getBrowserTimezone(),
    locale: getBrowserLocale(),
    section: 'spaceloop',
  };

  return getUserClient()
    .post('/register', postData)
    .finally(() => {
      dispatch(signupNotLoading());
    });
}
