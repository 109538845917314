/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef, useState } from 'react';

export const useMarkerMenu = (onClosing?: Function) => {
  const [x, setX] = useState<string | number>(0);
  const [y, setY] = useState<string | number>(0);

  const [showMenu, setShowMenu] = useState(false);

  const inputEl = useRef<HTMLElement>(null);

  const handleContextMenu = (e: MouseEvent) => {
    setTimeout(() => {
      e.preventDefault();
      setX(e.clientX);
      setY(e.clientY);
      setShowMenu(true);
    }, 0);
  };

  const handleClose = () => {
    if (onClosing) onClosing();
    setShowMenu(false);
  };

  useEffect(() => {
    if (inputEl.current !== null) {
      inputEl.current.addEventListener('click', handleContextMenu);
      return () => {
        if (inputEl.current !== null) {
          inputEl.current.removeEventListener('click', handleContextMenu);
        }
      };
    }
  }, []);

  return { x, y, showMenu, inputEl, handleClose };
};
