import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttrSlugType } from '../../../interfaces/attributes.interface';
import { OfficeParams } from '../../../interfaces/office.interface';
import { getCompanyValueBySlug } from '../../../services/attributes';
import { RootState, store } from '../../store';
import { deleteOffice, updateOffice } from '../../store/office/actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { FloorState, selectFloor } from '../../store/floor/floorSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from '../../../components/modals/DeletionDialog';
import { useTranslation } from 'react-i18next';

interface OfficeEditFormProps {
  id: string;
  currentName: string;
  currentLocation: string;
}

export const OfficeEditForm = (props: OfficeEditFormProps): React.ReactElement => {
  const [t] = useTranslation();
  const [name, setName] = useState<string>(props.currentName);
  const [location, setLocation] = useState<string>(props.currentLocation);

  const [oldName, setOldName] = useState<string>(props.currentName);
  const [oldLocation, setOldLocation] = useState<string>(props.currentLocation);

  const currentFloors: FloorState = useSelector(selectFloor);
  useEffect(() => {
    setName(props.currentName);
    setLocation(props.currentLocation);
    setOldName(props.currentName);
    setOldLocation(props.currentLocation);
  }, [props]);

  const isChanged = !(name !== oldName || location !== oldLocation);

  const [isEditExpended, setIsEditExpended] = useState(false);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const handleUpdate = () => {
    const timezone = getCompanyValueBySlug(store.getState() as RootState, AttrSlugType.Timezone);

    const companyLanguage = getCompanyValueBySlug(store.getState() as RootState, AttrSlugType.SystemLanguage);

    const updatedOffice: Partial<OfficeParams> = {
      name: {
        [companyLanguage]: name,
      },
      location: {
        [companyLanguage]: location,
      },
      timezone,
    };
    dispatch(updateOffice(updatedOffice, props.id));
    setIsEditExpended(false);
  };

  const handleDelete = () => {
    setOpen(false);
    dispatch(deleteOffice(props.id, navigate));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        <Accordion key={props.id} expanded={isEditExpended}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setIsEditExpended(!isEditExpended)}
          >
            <Typography>{t('spaceloop.offices.edit_office')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label={t('spaceloop.office.name')}
              type="text"
              variant="outlined"
              fullWidth
              id="nameInput"
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <TextField
              label={t('spaceloop.office.location')}
              type="text"
              variant="outlined"
              fullWidth
              id="locationInput"
              value={location}
              onChange={event => setLocation(event.target.value)}
            />
            <Grid item flexDirection="row" display="flex">
              <Grid item flexDirection="row" justifyContent="flex-start" display="flex">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  color="error"
                  style={{ marginRight: '1rem' }}
                  disabled={currentFloors.floors.length !== 0}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item flexDirection="row" justifyContent="flex-end" display="flex" flex={1}>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleUpdate();
                  }}
                  color="error"
                  disabled={isChanged}
                >
                  {t('spaceloop.button.save')}
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      <DeleteDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleDelete}
        title={t('spaceloop.floors.delete_modal_title')}
        text={t('spaceloop.floors.delete_modal_content')}
      />
    </>
  );
};
