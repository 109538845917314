import React, { useEffect } from 'react';
import { createTheme, Stack, styled, ThemeOptions, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './components/sidebar';
import { appIsLoaded, initUserLogin } from './app/store/session/actions';
import DefaultTheme from './app/styles/themes/DefaultTheme';
import { loadTokenIfExists } from './app/store/session/cookie';
import { SnackbarProvider } from 'notistack';
import { Routing } from './app/Routing';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { selectSession, SessionState } from './app/store/session/sessionSlice';
import { Page } from './components/loadable/Page';
// Important for booking to be able to store utc'ified times
dayjs.extend(utc);
dayjs.extend(timezone);

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    landing: true;
    basic: true;
    primary: true;
    secondary: true;
  }
}

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantDefault {
    background-color: #4e546b;
  }

  &.SnackbarItem-variantInfo {
    background-color: #4e546b;
  }

,
& . SnackbarItem-variantSuccess {
  background-color: #4e546b;
},
& . SnackbarItem-variantWarning {
  background-color: #4e546b;
}

  &.SnackbarItem-variantError {
    background-color: #4e546b;
  }

,
`;

function App(): React.ReactElement {
  const dispatch = useDispatch<any>();
  const theme = createTheme(DefaultTheme as ThemeOptions);
  const token = loadTokenIfExists();
  const currentSession: SessionState = useSelector(selectSession);

  useEffect(() => {
    const init = async () => {
      if (token) {
        await dispatch(initUserLogin(token));
      }
      dispatch(appIsLoaded());
    };
    init();
  }, [token]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <StyledSnackbarProvider>
          <Stack direction="row">
            {
              currentSession.appLoading
                ? <Page loading></Page>
                : <BrowserRouter>
                  {currentSession.authStatus && <Sidebar />}
                  <Routing />
                </BrowserRouter>
            }
          </Stack>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
