import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Office } from '../../../interfaces/office.interface';

export interface OfficeState {
  officeModalOpen: boolean;
  offices: Array<Office>;
  officeLoading: boolean;
}

const initialState: OfficeState = {
  officeModalOpen: false,
  offices: [],
  officeLoading: false,
}

export const officeSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    resetOffice: () => initialState,
    officeLoading: state => {
      state.officeLoading = true;
    },
    officeNotLoading: state => {
      state.officeLoading = false;
    },
    officeModalOpen: state => {
      state.officeModalOpen = true;
    },
    officeModalClose: state => {
      state.officeModalOpen = false;
    },
    setOffices: (state, action: PayloadAction<Array<Office>>) => {
      state.offices = action.payload;
    },
  },
});

export const { officeLoading, officeNotLoading, officeModalOpen, officeModalClose, setOffices, resetOffice } =
  officeSlice.actions;

export const selectOffice = (state: RootState) => state.office;

export default officeSlice.reducer;
