import { Button, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import EmailIcon from '../../components/icons/EmailIcon';
import PasswordIcon from '../../components/icons/PasswordIcon';
import { useDispatch, useSelector } from 'react-redux';

import { selectSession, SessionState } from '../store/session/sessionSlice';
import { getCompany } from '../../api/session/session.api';
import { Company } from '../../interfaces/company.interface';
import { initUserLogin, loginUser } from '../store/session/actions';
import { useNotifications } from '../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

type companyTokenPair = {
  company: Company;
  token: string;
};

export const Login = (): React.ReactElement => {
  const { showError, closeNotifications } = useNotifications();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [companies, setCompanies] = useState<Array<companyTokenPair>>([]);
  const currentSession: SessionState = useSelector(selectSession);
  const [t] = useTranslation();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleLogin = (): void => {
    setCompanies([]);
    closeNotifications();
    dispatch(loginUser({ identifier: email, password }));
  };

  useEffect(() => {
    if (!currentSession.authStatus) {
      return;
    }
    const redirect = searchParams.get('redirect') || '/';
    navigate(redirect.replace(/\//, '') !== 'login' ? redirect : '/');
  });

  useEffect(() => {
    if (
      currentSession.loginLoading &&
      currentSession.accounts.tokens.length > 0 &&
      !currentSession.loginFailed
    ) {
      fetchCompanyList(currentSession.accounts.tokens);
    }
    if (currentSession.loginFailed) {
      showError(t('x.error.login_failed') as string);
    }
  }, [currentSession.accounts.tokens, currentSession.loginFailed]);

  const handleCompanySelect = (token: string): void => {
    dispatch(initUserLogin(token));
  };

  const fetchCompanyList = (tokens: Array<string>) => {
    const loadCompanyTokenPair = (token: string): Promise<companyTokenPair> =>
      new Promise((resolve, reject) => {
        getCompany(token)
          .then(company => {
            resolve({ company, token });
          })
          .catch(err => {
            reject(err);
          });
      });

    const companyPromises = tokens.map(token => loadCompanyTokenPair(token));

    Promise.all(companyPromises).then(data => {
      const filtered = data.filter(pair => {
        return pair.company.features.booking;
      });
      if (filtered.length === 1) {
        handleCompanySelect(filtered[0].token);

        return;
      }
      if (filtered.length === 0) {
        setPassword('');
        showError('booking.not_enabled');

        return;
      }
      setCompanies(filtered);
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', background: 'linear-gradient(to right,#2c3f73,#0c1733)' }}
      display="flex"
    >
      <Grid>
        {companies.length === 0 ? (
          <>
            <Grid paddingBottom={8} textAlign={'center'}>
              <Typography variant="h3" color={'#fff'} style={{ fontSize: '3rem' }}>
                {'Log in'}
              </Typography>
            </Grid>
            <Grid width={'359px'}>
              <Grid width="100%">
                {' '}
                <TextField
                  label={t('spaceloop.login.email')}
                  type="string"
                  variant="outlined"
                  fullWidth
                  id="deskloopEmailInput"
                  onChange={event => setEmail(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon sx={{ width: 25, height: 25 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid width="100%">
                {' '}
                <TextField
                  label={t('spaceloop.login.password')}
                  type="password"
                  variant="outlined"
                  fullWidth
                  id="deskloopPasswordInput"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon sx={{ width: 25, height: 25 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid item flexDirection={'row'}>
                  <Link href="/forgot-password" underline="hover" color="#fff" fontSize={'13px'} textAlign={'left'}>
                    {t('x.login.no_password_link.text')}
                  </Link>
                </Grid>
              </Grid>
              <Grid item paddingTop={5} width="100%">
                <Button variant="landing" onClick={handleLogin} color="error">{t('spaceloop.button.login')}</Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid paddingBottom={5} textAlign={'center'}>
              <Typography variant="h3" color={'#fff'} style={{ fontSize: '3rem' }}>
                {t('x.login.mulitple_accounts.header')}
              </Typography>
            </Grid>
            <Grid width={'443px'}>
              <Grid item paddingTop={5} width="100%">
                {companies.map(item => (
                  <Button
                    key={item.company.name}
                    variant="basic"
                    onClick={() => handleCompanySelect(item.token)}
                  >
                    {item.company.name}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
