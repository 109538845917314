import { AttrSlugType, Attribute } from '../interfaces/attributes.interface';
import { RootState } from '../app/store';
import { getAttributeBySlug } from '../app/store/attribute/actions';
import { SessionState } from '../app/store/session/sessionSlice';

export function getTimezoneAttribute(state: RootState): Attribute {
  return getAttributeBySlug(state, AttrSlugType.Timezone);
}

export function getSystemLanguageAttribute(state: RootState): Attribute {
  return getAttributeBySlug(state, AttrSlugType.SystemLanguage);
}

export function getContentLanguageAttribute(state: RootState): Attribute {
  return getAttributeBySlug(state, AttrSlugType.ContentLanguage);
}

export function getManagerAttribute(state: RootState): Attribute {
  return getAttributeBySlug(state, AttrSlugType.Manager);
}

export function getCompanyValueBySlug(state: RootState, slug: AttrSlugType) {
  try {
    const uuid = getAttributeBySlug(state, slug).uuid;
    const session: SessionState = state.session;
    return session.company?.setting_attributes[uuid];
  } catch (error) {
    return '';
  }
}
