import { NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { getBookingClient } from '../../../api/api-client';
import { OfficeParams } from '../../../interfaces/office.interface';
import { officeLoading, officeNotLoading, setOffices } from './officeSlice';

export const getOffices =
  () =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(officeLoading());
    return getBookingClient()
      .get('api/offices')
      .then(response => {
        dispatch(setOffices(response.data));
        dispatch(officeNotLoading());
      });
  };

export const addOffice =
  (office: OfficeParams, navigate: NavigateFunction) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(officeLoading());
    return getBookingClient()
      .post('/api/offices', {
        name: office.name,
        location: office.location,
        timezone: office.timezone,
      })
      .then(response => {
        navigate(`/offices/${response.data.id}`);
        dispatch(getOffices() as any as AnyAction);
        dispatch(officeNotLoading());
      });
  };

export const updateOffice =
  (office: Partial<OfficeParams>, id: string) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(officeLoading());
    return getBookingClient()
      .patch(`/api/offices/${id}`, {
        name: office.name,
        location: office.location,
      })
      .then(() => {
        dispatch(getOffices() as any as AnyAction);
        dispatch(officeNotLoading());
      });
  };

export const deleteOffice =
  (id: string, navigate: NavigateFunction) =>
  (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(officeLoading());
    return getBookingClient()
      .delete(`/api/offices/${id}`)
      .then(() => {
        navigate('/deleted-office');
        dispatch(getOffices() as any as AnyAction);
        dispatch(officeNotLoading());
      });
  };
