import React, { FC } from 'react';
import { selectSession, SessionState } from '../../app/store/session/sessionSlice';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface Props {
  publicOnly?: boolean
}

export const PublicRoute: FC<React.PropsWithChildren<Props>> = ({ children, publicOnly }) => {
  const currentSession: SessionState = useSelector(selectSession);

  return !currentSession.authStatus || (currentSession.authStatus && !publicOnly)
    ? <>{children}</>
    : <Navigate to="/" replace />;
};
