import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const mapKeyToData = (data: Array<Record<string, any>> = [], key = 'id', valKey?: string) =>
  data.reduce((acc: Record<string, any>, item) => {
    acc[item[key]] = valKey ? item[valKey] : item;
    return acc;
  }, {});

export const trimValues = (obj: Record<string, any>): Record<string, any> =>
  Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    if (typeof obj[key] === 'string') {
      acc[key] = obj[key].trim();
    } else if (Array.isArray(obj[key])) {
      acc[key] = obj[key];
    } else if (obj[key] instanceof Object) {
      acc[key] = trimValues(obj[key]);
    }
    return acc;
  }, {});

export async function asyncForEach(array: any[], callback: (item: any) => Promise<any>) {
  const results = array.map(callback);
  await Promise.all(results);
}

export function finalizeAll<T>(promises: Array<Promise<T>>): Promise<{ resolves: T[]; rejects: any[] }> {
  const promisesLength = promises.length;
  let count = 0;
  return new Promise(res => {
    const resolves: T[] = [];
    const rejects: any[] = [];
    promises.forEach((promise, idx) => {
      promise
        .then(result => {
          resolves[idx] = result;
        })
        .catch(error => {
          rejects[idx] = error;
        })
        .finally(() => {
          count += 1;
          if (count >= promisesLength) {
            res({ resolves, rejects });
          }
        });
    });
  });
}
export const isApiError = (rsp: AxiosResponse): boolean => rsp.status >= 400;
